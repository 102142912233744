@import "styles/variables";
@import "styles/mixins";

.bp-project-list-filter-dropdown {
  min-height: auto;
  max-height: calc(100vh - 100px);

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: $on-surface-light-secondary;
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 1.125rem;
    line-height: 2rem;
    color: $on-surface-light-primary;
    margin-bottom: 0.75rem;
  }

  &__content {
    display: flex;
    gap: 1.5rem;
    min-height: 0;
    overflow-y: auto;
  }

  &__column {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
  }

  &__footer {
    display: flex;
    align-items: center;

    & button {
      flex: 0 1 50%;
    }
  }
}
