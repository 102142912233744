@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-result-summary-chapter {
  display: flex;
  gap: 0.5rem;
  flex: 1 1 395px;
  background-color: $surface-01;

  &__name {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__title {
    color: $on-surface-light-primary;
  }

  &__control {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__icon {
    font-size: 2rem;
  }
}
