@use "styles/variables" as *;

.bp-share-project-user-list {
  &__header-cell {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding-bottom: 2rem;
    border-bottom: none;
  }

  &__body-row {
    --cds-border-subtle: #{$border-03};

    background-color: $surface-01;
    border-right: 1px solid $border-03;
    border-left: 1px solid $border-03;
    margin-bottom: 0.5rem;
  }

  &__body-cell {
    vertical-align: middle;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.85rem;
  }

  &__body-button-cell {
    vertical-align: middle;
    padding: 0;
  }
}
