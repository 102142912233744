@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-schema-select-screen {
  height: 100%;

  display: flex;
  flex-direction: column;

  &__notification {
    max-inline-size: none;
    min-block-size: 5rem;

    .cds--inline-notification__details {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .cds--inline-notification__icon {
        margin: 0;
      }
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    color: $on-surface-light-secondary;
    margin-bottom: 1.5rem;
  }

  &__divider {
    margin-bottom: 1.5rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.375rem;
    background-color: $surface-01;
    padding: 1rem;
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  &__schema {
    display: flex;
    gap: 0.5rem;
    max-height: 500px;
  }

  &__schema-options {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    padding: 1rem;
    width: 50%;
    max-width: 250px;
  }

  &__schema-container {
    display: none;
    width: 100%;
    border-left: 1px solid $border-05;

    @include breakpoint-up("md") {
      display: flex;
    }
  }

  &__schema-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 2rem;
  }

  &__schema-info {
    display: none;
    flex-direction: column;

    @include breakpoint-up("md") {
      display: flex;
    }
  }

  &__schema-title {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
  }

  &__schema-description {
    font-size: 1rem;
    line-height: 1.25rem;
    color: $on-surface-light-tertiary;
  }

  &__schema-image {
    flex: 1 1 0;
    min-height: 0;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
}
