@use "styles/variables" as *;

.bp-report-document-page-evaluation-overview {
  &__title {
    font-size: 12pt;
    line-height: 18pt;
    color: $on-surface-light-primary;
    margin-bottom: 12pt;
  }

  &__investments {
    display: flex;
    gap: 12pt;
    width: 100%;
    margin-bottom: 24pt;
  }

  &__modification {
    margin-bottom: 24pt;
  }

  &__modification-title {
    padding-left: 12pt;
    font-size: 9pt;
    line-height: 13.5pt;
    color: $on-surface-light-secondary;
  }

  &__modification-description {
    background-color: $surface-02;
    padding: 6pt 12pt;
    font-size: 7.5pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
    column-count: 2;
    column-gap: 12pt;
  }

  &__savings {
    display: flex;
    flex-wrap: wrap;
    gap: 12pt;
    margin-bottom: 12pt;
    font-size: 12pt;
  }

  &__saving-area {
    display: flex;
    justify-content: flex-start;
  }

  &__saving-name {
    margin-right: auto;
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
  }

  &__saving-value {
    margin-left: 2pt;
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-bold;
    color: $on-surface-light-secondary;
  }

  &__saving-unit {
    margin-left: 2pt;
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-bold;
    color: $on-surface-light-secondary;
  }

  &__description-title {
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-bold;
    color: $on-surface-light-secondary;
  }
  &__description-text {
    font-size: 7.5pt;
    line-height: 10pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
  }
}
