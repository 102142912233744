@use "@carbon/colors" as *;
@use "styles/variables" as *;

.bp-text-area-field {
  textarea {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
    padding: 1rem 1rem 1rem 1.5rem;
    resize: none;

    &::placeholder {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-light;
      color: $on-surface-light-secondary;
    }

    &:hover {
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
    }

    &:focus {
      background-color: $surface-03;
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
      outline: none;
    }
  }

  &--gray {
    textarea {
      --cds-field-02: #{$surface-02};
      background-color: $surface-02;
    }
  }

  &--white {
    textarea {
      background-color: $surface-01;
    }
  }

  &--outlined {
    textarea {
      border-left: 1px solid $border-04;
      border-bottom: 1px solid $border-04;

      &:hover {
        border-left: 1px solid $belimo-orange-carbon-hovered;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        border-left: none;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
        outline: none;
      }
    }
  }

  &--invalid {
    textarea {
      border-bottom: 1px solid $border-danger;
    }

    label.cds--label {
      color: $on-surface-light-danger;
    }
  }
}
