@use "styles/variables" as *;

.bp-custom-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 1rem;
  line-height: 2rem;
  min-block-size: auto;
  height: fit-content;

  &.cds--btn {
    --cds-button-danger-primary: #{$interaction-danger};

    &--disabled {
      --cds-button-disabled: #{$surface-gray};
      --cds-text-on-color-disabled: #{$on-interaction-disabled};
    }

    &--secondary {
      border: none;
      background-color: $on-surface-light-secondary;

      &:hover {
        background-color: $on-surface-light-primary;
      }

      &:focus {
        background-color: $on-surface-light-primary;
        box-shadow: none;
      }

      &.cds--btn--disabled {
        background-color: $interaction-primary-disabled;
      }
    }

    &--primary {
      border: none;

      --cds-button-primary: #{$belimo-orange-carbon};
      --cds-button-primary-active: #{$belimo-orange-carbon-hovered};
      --cds-text-on-color: #{$on-surface-light-primary};

      &:hover {
        --cds-button-primary-hover: #{$belimo-orange-carbon-hovered};
      }

      &:focus {
        background-color: $belimo-orange-carbon-hovered;
        box-shadow: none;
      }
    }

    &--tertiary {
      border: none;
      outline: 1px solid $border-04;
      color: $on-surface-light-primary;
      background-color: transparent;

      &:hover {
        color: $on-surface-light-primary;
        outline: 1px solid $border-dark;
        background-color: transparent;
      }

      &:focus {
        color: $on-surface-light-primary;
        background-color: transparent;
        outline: 1px solid $border-dark;
        box-shadow: none;
      }

      &.cds--btn--disabled {
        background-color: $interaction-primary-disabled;
        outline: 1px solid $on-surface-light-disabled;
        color: $on-interaction-disabled;
      }
    }

    &--ghost {
      block-size: auto;
      inline-size: auto;
      border: none;
      color: $belimo-orange-carbon-hovered;

      &:hover {
        background-color: $surface-03;
      }

      &:focus {
        border: none;
        box-shadow: none;
      }

      svg {
        margin: 0;
      }

      &.cds--btn--icon-only {
        color: $on-interaction-dark;
      }

      &.cds--btn--disabled {
        background-color: $interaction-primary-disabled;
        color: $on-interaction-disabled;
      }
    }

    &--danger {
      border: none;

      &.cds--btn--disabled {
        background-color: $surface-04;
        color: $surface-03;
      }
    }

    &.cds--btn--icon-only {
      > :first-child {
        margin: 0;
        min-inline-size: auto;
      }
    }
  }

  &--sm.cds--btn {
    padding: 0 1rem;

    &.cds--btn--icon-only {
      padding: 0.25rem;
    }
  }

  &--md.cds--btn {
    padding: 0.25rem 1rem;

    &.cds--btn--icon-only {
      padding: 0.5rem;
    }
  }

  &--lg.cds--btn {
    padding: 0.5rem 1rem;

    &.cds--btn--icon-only {
      padding: 0.75rem;
    }
  }

  &--xl.cds--btn,
  &--2xl.cds--btn {
    padding: 0.75rem;

    &.cds--btn--icon-only {
      padding: 1rem;
    }
  }

  &--full-width {
    inline-size: auto;
    max-inline-size: none;
  }

  &--loading {
    background-color: $interaction-primary-disabled;
  }

  &__loading-icon {
    font-size: 1.5rem;

    animation: rotate 1s infinite linear;
  }

  @keyframes rotate {
    from {
      rotate: 0deg;
    }
    to {
      rotate: 360deg;
    }
  }
}
