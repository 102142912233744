@import "styles/variables";
@import "styles/mixins";

.bp-report-document-page-schema {
  $indent: 15pt;
  $chart: 110mm;

  &__section {
    & + & {
      margin-top: 28pt;
    }
  }

  &__horizontal-divider {
    display: flex;
    flex-shrink: 0;
    border: none;
    border-top: 0.75pt solid $border-03;
    width: 100%;
    margin: 0 0 9pt;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 3pt;
    margin-bottom: 6pt;
  }

  &__title-icon {
    font-size: 18pt;
  }

  &__comment-title {
    font-size: 9pt;
    line-height: 13.5pt;
    color: $on-surface-light-secondary;
    margin-bottom: 6pt;
  }

  &__comment-text {
    background-color: $surface-02;
    padding: 6pt 12pt;
    font-size: 7.5pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-light;

    max-height: 12pt * 3 + 6pt;
    overflow: hidden;
    white-space: pre-line;
  }

  &__schema {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7pt 0;
  }
  &__chart {
    width: $chart;
    height: $chart * 0.75;
  }
  &__hint {
    color: rgba(0, 0, 0, 0.6);
    font-weight: $font-weight-regular;
    font-size: 9pt;
  }
}
