@import "styles/variables";
@import "styles/mixins";

.bp-schema-input-layer {
  width: 100%;
  height: 100%;

  position: relative;
  overflow: hidden;
  pointer-events: none;

  &__container {
    position: absolute;
    pointer-events: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    position: absolute;
    justify-content: center;
    pointer-events: auto;
    padding: 0;
    border-radius: 4px;

    &:hover {
      opacity: 0.5;
    }
  }

  &__icon {
    visibility: visible;

    &--hidden {
      visibility: hidden;
    }
  }
}
