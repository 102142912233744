@use "styles/variables" as *;

.bp-custom-menu-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-inline-size: none;
  padding: var(--bp-custom-menu-item-padding, 1.25rem 1.5rem);
  text-decoration: none;
  block-size: var(--bp-custom-menu-item-block-size, 4rem);
  pointer-events: auto;

  &:hover {
    background-color: $surface-03;
  }

  &:focus {
    outline: none;
    outline-offset: -2px;
  }

  &--flipped {
    justify-content: flex-start;
    gap: 1.5rem;
  }

  &--disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &-wrapper {
    display: flex;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
  }
}
