@use "styles/variables" as *;

.bp-energy-efficiency-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: $surface-01;
  line-height: 2em;
  white-space: nowrap;

  width: 3.375em;
  clip-path: polygon(0 0, 3.041em 0, 100% 50%, 3.041em 100%, 0 100%);
  height: 1.333em;
  padding-left: 0.5em;

  &--A\+ {
    background-color: $efficiency-class-a-plus;

    &-sized {
      padding-left: 0.25em;
      width: 1.75em;
      clip-path: polygon(0 0, 1.416em 0, 100% 50%, 1.416em 100%, 0 100%);
    }
  }

  &--A {
    background-color: $efficiency-class-a;

    &-sized {
      padding-left: 0.25em;
      width: 2.25em;
      clip-path: polygon(0 0, 1.916em 0, 100% 50%, 1.916em 100%, 0 100%);
    }
  }

  &--B {
    background-color: $efficiency-class-b;

    &-sized {
      padding-left: 0.25em;
      width: 2.708em;
      clip-path: polygon(0 0, 2.375em 0, 100% 50%, 2.375em 100%, 0 100%);
    }
  }

  &--C {
    background-color: $efficiency-class-c;

    &-sized {
      padding-left: 0.25em;
      width: 3.25em;
      clip-path: polygon(0 0, 2.916em 0, 100% 50%, 2.916em 100%, 0 100%);
    }
  }

  &--D {
    background-color: $efficiency-class-d;

    &-sized {
      padding-left: 0.25em;
      width: 3.75em;
      clip-path: polygon(0 0, 3.416em 0, 100% 50%, 3.416em 100%, 0 100%);
    }
  }

  &--unknown {
    background-color: $efficiency-class-disabled;
  }

  &--disabled {
    background-color: $surface-04;
  }
}
