@use "styles/variables" as *;

.bp-report-form-section-no-schema {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: $surface-warning-01;

  &__title {
    color: $on-surface-light-warning;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.28px;
    padding: 3.25rem 2rem 0;
  }

  &__description {
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0 2rem;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
  }

  &__action-button {
    font-size: 1.5rem;
  }
}
