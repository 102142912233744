@use "styles/variables" as *;

.bp-notification {
  --cds-background-inverse: #{$on-surface-light-secondary};

  display: flex;
  gap: 0.75rem;
  align-items: center;
  block-size: 5rem;
  inline-size: auto;

  .cds--toast-notification__icon {
    --cds-support-success-inverse: #{$surface-success-03};
    margin: 0;
  }

  .cds--toast-notification__title {
    font-weight: $font-weight-regular;
    font-size: 1.125rem;
    margin: 0;
  }

  &.cds--toast-notification--success {
    --cds-support-success-inverse: #{$surface-success-02};

    .cds--toast-notification__title {
      color: $on-surface-dark-success;
    }
  }

  &.cds--toast-notification--error {
    .cds--toast-notification__title {
      color: $on-surface-dark-danger-light;
    }
  }

  .cds--toast-notification__close-button {
    min-block-size: 2rem;
    min-inline-size: 2rem;

    block-size: 2rem;
    inline-size: 2rem;

    margin-right: 1.5rem;

    .cds--toast-notification__close-icon {
      --cds-icon-inverse: #{$on-surface-light-disabled};

      width: 100%;
      height: 100%;
    }
  }

  &-wrapper {
    position: fixed;
    top: 4.5rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;

    // override the React Carbon's modal overlay
    z-index: 9001;
  }
}
