@use "styles/variables" as *;

.bp-feedback-sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin: auto;

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-regular;
    color: $on-surface-light-secondary;
  }
}
