@use "styles/variables" as *;

.bp-report-document-page-cover {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__brand {
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 24pt;
  }

  &__brand-cover {
    display: block;
    width: 100%;
    height: 405pt;
    object-fit: cover;
    object-position: top center;
  }

  &__brand-name {
    display: block;
    position: absolute;
    left: -15mm;
    top: 40%;
    width: 187.5pt;
    height: 187.5pt;

    padding: 24pt;
    overflow: hidden;

    color: white;
    background-color: $belimo-orange-carbon;

    font-size: 18pt;
    line-height: 24pt;
    font-weight: $font-weight-regular;
    overflow-wrap: break-word;
  }

  &__title {
    max-width: 300pt;
    font-size: 30pt;
    line-height: 36pt;
    color: $on-surface-light-primary;
    font-weight: $font-weight-regular;
    margin-bottom: 12pt;
  }

  &__description {
    display: flex;
    justify-content: space-between;
    max-height: 200pt;
    overflow: hidden;
    margin-bottom: auto;
  }

  &__description-goals {
    display: flex;
    flex-direction: column;
    margin-right: 36pt;
  }

  &__description-goals-title {
    font-size: 9pt;
    line-height: 13.5pt;
    font-weight: $font-weight-regular;
    color: $on-surface-light-primary;
    margin-bottom: 6pt;
  }

  &__description-goals-text {
    font-size: 12pt;
    line-height: 18pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-primary;
  }

  &__description-details {
    flex-shrink: 0;
    width: 150pt;
  }

  &__description-details-section {
    padding-bottom: 6pt;
    margin-bottom: 6pt;
    border-bottom: 0.75pt solid $border-03;
  }

  &__description-details-title {
    font-size: 9pt;
    line-height: 13.5pt;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-regular;
    margin-bottom: 6pt;
  }

  &__description-details-text {
    font-size: 7.5pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
  }

  &__address {
    margin-top: 10pt;
    font-size: 10pt;
    font-weight: $font-weight-regular;
  }
  &__date {
    margin-top: 20pt;
    font-size: 10pt;
    font-weight: $font-weight-regular;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__footer-title {
    font-size: 9pt;
    line-height: 13.5pt;
    font-weight: $font-weight-regular;
    color: $on-surface-light-secondary;
    border-bottom: 0.75pt solid $border-03;
    margin-bottom: 12pt;
  }

  &__footer-contacts {
    display: flex;
  }

  &__footer-contact {
    white-space: nowrap;
    font-size: 7.5pt;
    line-height: 12pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
    margin-right: 18pt;
  }

  &__footer-logo {
    svg {
      width: 116.25pt;
      height: 48pt;
    }
  }

  &__organization {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6pt;
    margin-bottom: 12pt;
    min-height: 0;
  }

  &__organization-title {
    font-size: 9pt;
    line-height: 12pt;
  }

  &__organization-logo {
    object-fit: contain;
    max-height: 37.5pt;
    max-width: 127.5pt;
  }
}
