@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as * ;

.bp-project-list-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
  padding: 1.5rem 2.5rem;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  &__table {
    display: grid;
    row-gap: 0.5rem;
    column-gap: 0;
    grid-template-columns: 6rem minmax(300px, 600px) minmax(auto, 7.5rem) auto auto 3.5rem;
    grid-auto-rows: 1fr;
  }

  &__grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  &__empty-text {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    margin: auto;
  }
}
