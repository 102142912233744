@use "styles/variables" as *;

.bp-request-access-modal {
  &__success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__success-text {
    color: $on-surface-light-secondary;
    font-size: 1.125rem;
    line-height: 2rem;
  }

  &__form-content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
}
