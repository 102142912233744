@use "styles/variables" as *;

.bp-report-form-section-preview {
  transform: none;
  transform-origin: 0 0;

  &__page {
    // user-select: none;
    // pointer-events: none;

    background-color: #fff;
    box-shadow: 0 8px 12px 4px $background-paper-elevation-level-05;

    & + & {
      margin-top: 1rem;
    }
  }

  &__content {
    &--disabled {
      opacity: 0.5;
    }
  }
}
