@use "styles/variables" as *;

.bp-user-row {
  display: flex;
  flex-direction: column;
  height: 2.5rem;
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  &__header {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0.5rem 1rem;
    background-color: $surface-01;
    border-top: 1px solid $border-04;
    cursor: pointer;

    &--opened {
      background-color: $surface-03;
    }
  }

  &__icon {
    font-size: 1.5rem;
  }

  &__checkbox {
    --bp-checkbox-radius: 0;
    --bp-checkbox-border-width: 1px;
  }

  &__checkbox-wrapper {
    display: flex;
    flex-shrink: 0;
    width: 1.5rem;
  }

  &__header-text {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
    margin-right: auto;
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 1.5rem 2rem 5.625rem;
    background-color: $surface-03;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__content-row {
    display: flex;
    gap: 1rem;
  }

  &__detail {
    display: flex;
    gap: 1rem;
    width: 50%;
    flex-shrink: 0;
  }

  &__input-full-width {
    width: 100%;
  }

  &__text {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
  }
}
