@use "styles/variables" as *;

.bp-checkbox {
  position: relative;
  display: flex;
  flex-shrink: 0;
  border: var(--bp-checkbox-border-width, 2px) solid $border-04;
  border-radius: var(--bp-checkbox-radius, 2px);
  background-color: transparent;
  height: 1rem;
  width: 1rem;
  margin: 0.25rem;

  &--checked {
    border: none;

    &::before {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: $surface-black;
      border-radius: var(--bp-checkbox-radius, 2px);
      content: "";
    }

    &::after {
      position: absolute;
      left: 50%;
      top: 25%;
      background: 0 0;
      width: 50%;
      height: 25%;
      border-left: 1.5px solid $on-interaction-light;
      border-bottom: 1.5px solid $on-interaction-light;
      content: "";
      margin: auto;
      transform: rotate(-45deg) translateX(-50%) translateY(-50%);
    }
  }

  &--partial {
    border: none;

    &::before {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: $surface-black;
      border-radius: var(--bp-checkbox-radius, 2px);
      content: "";
    }

    &::after {
      position: absolute;
      left: 50%;
      top: 45%;
      background: 0 0;
      width: 50%;
      height: 25%;
      border: none;
      border-bottom: 1.5px solid $on-interaction-light;
      content: "";
      margin: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .bp-checkbox {
        border: var(--bp-checkbox-border-width, 2px) solid $border-dark;
      }

      .bp-checkbox--checked {
        border: none;
      }

      .bp-checkbox--partial {
        border: none;
      }
    }

    &--disabled {
      cursor: auto;

      .bp-checkbox:not(.bp-checkbox--checked, .bp-checkbox--partial) {
        border: var(--bp-checkbox-border-width, 2px) solid $border-04;
      }

      .bp-checkbox--checked::before {
        background-color: $surface-04;
      }

      .bp-checkbox--partial::before {
        background-color: $surface-04;
      }

      .bp-checkbox__label {
        color: $on-interaction-disabled;
      }
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
    color: $surface-black;
    margin: 0 0 0 0.5rem;
  }
}
