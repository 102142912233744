@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-form-clear-confirmation-modal {
  &__body {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }
}
