@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-form-section-form-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include breakpoint-up("lg") {
    flex-direction: row;
  }

  &__label-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0 1.5rem;
    border-bottom: 1px solid $border-04;
    border-right: none;
    max-width: 150px;

    @include breakpoint-up("lg") {
      border-bottom: none;
      border-right: 1px solid $border-04;
      padding: 0 4.5rem 0 0;
    }
  }

  &__label-title {
    color: $on-surface-light-primary;
  }

  &__label {
    // minimal block's width to get rid of layout shifting
    width: $bp-energy-efficiency-label-d-width;
    font-size: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }
}
