@import "styles/variables";
@import "styles/mixins";

.bp-hvac-form-section {
  border: 1px solid $border-03;
  background-color: $surface-01;

  .cds--accordion__heading {
    &:hover {
      background-color: $surface-01;
      cursor: auto;
    }
  }

  .cds--accordion__wrapper {
    border-top: 1px solid $border-03;
  }

  .cds--accordion__content {
    padding: 0;
  }
}
