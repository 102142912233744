@use "styles/variables" as *;

.bp-saving-tile-amount {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 50%;

  &__title {
    font-size: 0.675em;
    line-height: 2em;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
  }

  &__amount {
    display: flex;
    gap: 0.25rem;
    border-bottom: 0.02em solid $border-04;
    font-size: 2.125em;
    line-height: 1.125em;
    padding-bottom: 0.25em;
  }
}
