@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-project-list-remove {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }

  &__description {
    color: $on-surface-light-secondary;
    text-align: center;
    white-space: pre-line;
  }
}
