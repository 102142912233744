@use "styles/variables" as *;

.bp-toggle-field {
  .cds--toggle__appearance {
    column-gap: 2rem;
  }

  .cds--toggle__text {
    font-size: 1.125rem;
    line-height: 2rem;
    color: $on-surface-light-primary;
  }
}
