.bp-bottom-navigation-helper {
  display: flex;
  justify-content: space-between;

  &__button-icon {
    font-size: 1.5rem;
  }

  &__forth-button {
    margin-left: auto;
  }
}
