@import "styles/variables";
@import "styles/mixins";

.bp-hvac-form-section-additionals {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 1rem;

  & > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }
}
