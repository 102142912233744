@use "styles/variables" as *;

.bp-project-list-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  &__title {
    font-size: 1.125rem;
    line-height: 2rem;
    white-space: pre-line;
    color: $on-surface-light-secondary;
    text-align: center;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  &__button {
    width: 100%;
  }

  &__button-icon {
    font-size: 1.5rem;
  }
}
