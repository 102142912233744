@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

.bp-custom-menu {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-height: 590px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $surface-02;
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  clip-path: inset(0 -10px -10px -10px);

  &::after {
    content: none;
  }

  &__button {
    width: 3.5rem;
    height: 3.5rem;

    &:hover {
      background-color: $surface-03;
    }

    &:focus {
      box-shadow: none;
      outline: none;
      background-color: $surface-03;
    }

    &--disabled {
      opacity: 0.25;
      pointer-events: none;
      cursor: not-allowed;
    }

    &.cds--overflow-menu.cds--overflow-menu--open {
      background-color: $surface-03;

      &:hover {
        background-color: $surface-03;
      }
    }
  }
}
