@use "styles/variables" as *;

.bp-announcements-empty {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 100%;

  &__icon {
    font-size: 4rem;
    color: $on-interaction-dark;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1.125rem;
    line-height: 2rem;
    text-align: center;
    color: $on-interaction-dark;
  }

  &__description {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: $font-weight-light;
    text-align: center;
    color: $on-surface-light-tertiary;
  }
}
