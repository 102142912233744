@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-delete-version-dialog {
  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
}
