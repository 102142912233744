@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-result-savings-overall {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: $surface-02;

  &__title {
    color: $on-surface-light-primary;
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__row {
    display: flex;
    padding: 0.75rem 1rem;
    min-height: 3.5rem;

    &--labels {
      padding: 1.25rem 1.5rem;
    }
    &--contained {
      border: 1px solid $border-03;
      background-color: $surface-01;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__row-name {
    color: $on-surface-light-primary;

    &--aligned {
      align-self: flex-start;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 1.5rem;
  }

  &__content-icon {
    font-size: 2rem;
  }

  &__assessment {
    display: flex;
  }

  &__assessment-tab {
    font-size: 1.5rem;
    width: 5.625rem;
  }

  &__assessment-divider {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
  }

  &__assessment-stack {
    font-size: 1.5rem;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.7rem;
    background-color: $surface-01;

    @include breakpoint-up("lg") {
      flex-direction: row;
    }
  }

  &__card-unit {
    display: flex;
    gap: 1.5rem;
  }

  &__saving-area {
    display: flex;
    padding: 0.5rem 0;
  }

  &__saving-description {
    flex: 1 1 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
  }

  &__saving-value,
  &__saving-unit {
    margin-left: 0.25rem;
    flex: 0 0 auto;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
  }
}
