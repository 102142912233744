@use "styles/variables" as *;

.bp-report-form-section-config {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background: #fff;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    color: $on-surface-light-primary;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1.5rem;
  }

  &__action {
    padding-top: 1.5rem;
  }

  &__button-icon {
    font-size: 2rem;
  }

  &__with-hint {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__hint {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.75rem;
    color: $on-surface-light-tertiary;
  }
}
