@use "styles/variables" as *;

.bp-password-change-form {
  max-inline-size: 50%;
  min-inline-size: 14.286rem;

  &__password-tip {
    font-size: 1rem;
    font-weight: $font-weight-regular;
    line-height: 1.5rem;

    color: $on-surface-light-secondary;
  }

  &__submit {
    inline-size: 100%;
    max-inline-size: none;
  }
}
