@use "styles/variables" as *;

.bp-dropdown-field {
  --cds-support-error: transparent;

  // input wrapper
  div.cds--list-box {
    max-block-size: none;
    block-size: fit-content;
    border: none;

    // input
    .cds--list-box__field {
      block-size: 2.5rem;
      border-bottom: 1px solid $border-04;

      &:hover {
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        background-color: $surface-03;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
        outline: none;
      }
    }

    // input text
    .cds--list-box__label {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-light;
      color: $on-surface-light-secondary;
    }

    // focused dropdown item
    .cds--list-box__menu-item--highlighted {
      outline: none;
    }

    // dropdown item
    .cds--list-box__menu-item__option {
      padding: 0.5rem 0;
    }
  }

  &--sm {
    div.cds--list-box {
      .cds--list-box__field {
        block-size: 2rem;
        padding: 0.25rem 0.75rem;
      }
    }
  }

  &--md {
    div.cds--list-box {
      .cds--list-box__field {
        block-size: 2.5rem;
        padding: 0.5rem 1rem;
      }
    }
  }

  &--lg {
    div.cds--list-box {
      .cds--list-box__field {
        block-size: 3rem;
        padding: 0.75rem 1rem;
      }
    }
  }

  &--xlg {
    div.cds--list-box {
      .cds--list-box__field {
        block-size: 3.5rem;
        padding: 0.75rem 1rem;
      }
    }
  }

  &--gray {
    div.cds--list-box {
      background-color: $surface-02;
    }
  }

  &--white {
    div.cds--list-box {
      background-color: $surface-01;
    }
  }

  &--outlined {
    div.cds--list-box {
      .cds--list-box__field {
        border-left: 1px solid $border-04;
        border-bottom: 1px solid $border-04;

        &:hover {
          border-left: 1px solid $belimo-orange-carbon-hovered;
          border-bottom: 1px solid $belimo-orange-carbon-hovered;
        }

        &:focus {
          border-left: none;
          border-bottom: 1px solid $belimo-orange-carbon-hovered;
          outline: none;
        }
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    height: fit-content;
  }

  &--disabled {
    div.cds--list-box {
      // input
      .cds--list-box__field {
        &:hover {
          border-bottom: 1px solid $border-04;
        }
      }
    }
  }

  &--invalid {
    div.cds--list-box {
      .cds--list-box__field {
        border-bottom: 1px solid $border-danger;

        &:hover {
          border-bottom: 1px solid $border-danger;
        }
      }
    }

    label.cds--label {
      color: $on-surface-light-danger;
    }
  }

  .cds--list-box__label {
    width: 100%;
    padding-right: 1.5rem;
  }

  .cds--list-box__invalid-icon {
    display: none;
  }

  // dropdown option menu
  .cds--list-box__menu {
    --cds-layer: #{$surface-02};
  }

  // dropdown option wrapper
  .cds--list-box__menu-item {
    min-block-size: 2.5rem;
    block-size: fit-content;

    --cds-layer-hover: #{$surface-03};
    --cds-layer-selected-hover: #{$surface-03};
    --cds-layer-selected: #{$surface-03};

    &__option {
      display: flex;
      align-items: center;
      min-block-size: 2.5rem;
      block-size: fit-content;
      border: none;
    }
  }
}
