@use "styles/variables" as *;

.bp-project-list-item-list-view {
  display: contents;
  height: fit-content;
  background-color: $surface-01;
  text-decoration: none;
  color: $on-surface-light-primary;

  &__title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  &__address {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  &__ownership-tag-tooltip {
    max-inline-size: 100%;
  }

  &__ownership-tag {
    padding: 0.25rem 0.75rem;
    height: 1.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    border: none;
    border-radius: 6.25rem;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--shared {
      color: $belimo-orange-carbon-hovered;
      background-color: $surface-tag;
    }

    &--owned {
      color: $on-surface-light-tertiary;
      background-color: $surface-03;
    }
  }

  &__description {
    color: $on-surface-light-secondary;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &__detail {
    color: $on-surface-light-secondary;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-right: 2rem;
    background-color: $surface-01;
    border-top: 1px solid $border-02;
    border-bottom: 1px solid $border-02;

    &:first-child {
      border-left: 1px solid $border-02;
    }

    &:last-child {
      padding-right: 0;
      border-right: 1px solid $border-02;
    }

    &--icon {
      align-items: center;
    }

    &--project {
      gap: 4px;
    }

    &--controls {
      flex-direction: row;
      align-items: center;

      .cds--popover-container {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .cds--tooltip-trigger__wrapper {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__loading {
    svg {
      fill: transparent;
    }
  }
}
