.bp-error-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 117px;
  height: 100%;

  &__title {
    font-size: 7.5rem;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 8rem;
  }

  &__subtitle {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    margin-top: 2rem;
  }

  &__description {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    width: 480px;
  }

  &__button {
    margin-top: 2rem;
  }

  &__button-icon {
    font-size: 2rem;
  }
}
