.bp-asset-upload {
  position: relative;
  overflow: hidden;
  width: 100%;

  &__button {
    width: 100%;
    height: 100%;
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.5);
  }
}
