@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-evaluation-results-savings {
  padding: 2rem;
  background-color: $surface-01;
  border: 1px solid $border-03;

  &__title {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $on-surface-light-secondary;
  }

  &__divider {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__grid {
    display: grid;
    grid-column-gap: 1rem;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
  }

  &__tile {
    padding: 1rem;
    background-color: $surface-02;
    border: 1px solid $border-03;
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + & {
      margin-top: 0.5rem;
    }
  }

  &__details-title {
    flex: 1 1 0;
    font-size: 1.125rem;
    line-height: 2rem;
    font-weight: $font-weight-light;
  }

  &__details-value {
    flex: 0 0 auto;
    font-size: 1.125rem;
    line-height: 2rem;
    white-space: nowrap;
  }

  &__details-unit {
    flex: 0 0 auto;
    margin-left: 0.25rem;
    white-space: nowrap;
  }

  &__main-unit {
    vertical-align: top;
    position: relative;
    font-size: 0.5em;
    top: 0;
    margin-left: 0.25rem;
  }
}
