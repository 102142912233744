@use "styles/variables" as *;

.bp-datebox-field {
  --cds-border-strong: #{$border-04};

  &-wrapper {
    position: relative;
    display: flex;
  }

  .cds--date-picker {
    width: 100%;

    .cds--date-picker-container {
      width: 100%;

      span {
        width: 100%;

        .cds--date-picker__input {
          --cds-code-02-font-family: "Roboto";

          block-size: fit-content;
          padding: 1.25rem 1.5rem;
          width: 100%;
        }

        .cds--date-picker__icon {
          display: none;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 2.75rem;
    right: 1rem;
    font-size: 2rem;
  }

  &--sm + .bp-datebox-field__icon {
    top: 2.1rem;
    font-size: 1.5rem;
  }

  &--sm .cds--date-picker .cds--date-picker-container span .cds--date-picker__input {
    padding: 0.35rem 1rem;
  }

  &--md + .bp-datebox-field__icon {
    top: 2.35rem;
    font-size: 1.5rem;
  }

  &--md .cds--date-picker .cds--date-picker-container span .cds--date-picker__input {
    padding: 0.6rem 1rem;
  }

  &--lg + .bp-datebox-field__icon {
    top: 2.55rem;
    font-size: 1.5rem;
  }

  &--lg .cds--date-picker .cds--date-picker-container span .cds--date-picker__input {
    padding: 0.85rem 1rem;
  }
}

.cds--date-picker__calendar {
  --cds-focus: #{$belimo-orange-carbon};
  --cds-button-primary: #{$belimo-orange-carbon};
  --cds-link-primary: #{$belimo-orange-carbon};
}
