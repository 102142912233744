@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-evaluation-form-view {
  display: flex;
  gap: 1rem;
  align-items: stretch;
  justify-content: space-between;

  &__tile {
    flex: 0 0 50%;
    padding: 2rem;
    background-color: $surface-01;
    border: 1px solid $border-03;
  }

  &__divider {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    color: $on-surface-light-secondary;
  }

  &__field {
    & + & {
      margin-top: 1rem;
    }
  }
}
