@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;

.bp-basedata-form-view {
  padding: 1.5rem 2rem 2rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1280px;
    margin: 0 auto;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    @include breakpoint-up("lg") {
      flex-direction: row;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    width: 100%;
    background-color: $surface-01;
  }

  &__card-row {
    display: flex;
    gap: 1rem;
  }

  &__field-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__type-field {
    width: 40%;
  }

  &__size-field {
    width: 40%;
  }

  &__unit-field {
    width: 20%;
  }

  &__street-field {
    width: 100%;
  }

  &__city-field {
    width: 100%;
  }

  &__country-field {
    width: 50%;
  }

  &__zip-field {
    width: 50%;
  }
}
