@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-report-form-view {
  padding: 1.5rem 2rem 2rem;

  &__wrapper {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    color: $on-surface-light-primary;
  }

  &__subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-tertiary;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__actions-button {
    min-width: 190px;
  }

  &__action-icon {
    font-size: 1.5rem;
  }

  &__section {
    display: flex;
    gap: 1rem;
  }
  &__section-preview {
    flex: 1 1 0;
  }
  &__section-config {
    flex: 0 0 30rem;
    min-width: 0;
  }
}
