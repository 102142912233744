@use "styles/variables" as *;

.bp-rating {
  display: flex;

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &-star {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;

    &--error {
      color: #da1e28;
    }

    &--selected {
      color: $surface-black;
    }
  }
}
