@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-router-loader {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  &__spinner {
    .cds--loading__stroke {
      stroke: $belimo-orange-carbon;
    }
  }
}
