@use "styles/variables" as *;

.bp-project-list-import-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__description {
    color: $on-surface-light-secondary;
    white-space: pre-line;
  }

  &__import-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border: 1px dashed $on-surface-light-tertiary;
    padding: 1.5rem;
    transition: all 0.5s ease;
    cursor: pointer;
    background-color: $surface-01;

    &:hover {
      background-color: $surface-03;
    }
  }

  &__pending-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1.5rem;
  }

  &__text {
    color: $on-surface-light-secondary;
    white-space: pre-line;
    text-align: center;
  }

  &__footer {
    justify-content: flex-start;
  }
}
