@import "styles/variables";
@import "styles/mixins";

.bp-hvac-result-summary-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid $border-03;
  background-color: $surface-01;

  &__title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5rem;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__buttons {
    display: flex;
    gap: 12px;
  }

  &__button-icon {
    font-size: 1.5rem;
  }

  &__comment-badge {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }

  &__comment-button {
    min-width: 272px;
  }
}
