@use "styles/variables" as *;

.bp-share-project-modal {
  &__body.cds--modal-content {
    background-color: $surface-02;
    padding: 2rem 1.5rem 1.5rem;
  }

  &__loading {
    margin: auto;
  }

  &__finish-button-icon {
    font-size: 1.5rem;
  }
}
