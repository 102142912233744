@use "styles/variables" as *;

.bp-file-upload {
  width: 100%;
  height: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &--disabled {
    cursor: not-allowed;
  }

  &__drop-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 1.25rem auto;
    font-size: 2rem;
  }

  &__warning {
    color: $text-warning;
    white-space: pre-line;
    text-align: center;
  }
}
