@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-result-savings-view {
  display: flex;
  flex-direction: column;

  &__divider {
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid $border-03;
    border-bottom: none;
    width: 100%;
    margin: 0;
  }

  &__tab-panel.cds--tab-content {
    background-color: $surface-01;
    padding: 0;
  }
}
