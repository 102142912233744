@use "styles/variables" as *;

.bp-energy-efficiency-stack {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  &__addition {
    flex: 0 0 0;
    width: 0;

    display: flex;
    align-items: stretch;
    justify-content: flex-end;
  }

  &__addition-value {
    align-self: flex-end;
    flex: 0 0 0;
    width: 0;
    height: 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__addition-line {
    margin-right: 0.5em;
    border-left: solid 1px $border-03;
  }

  &__addition-value-label {
    font-size: 2.5em;
    text-wrap: nowrap;
  }

  &__addition-value-arrow {
    display: flex;
    align-items: center;
  }

  &__stack {
    flex: 0 0 auto;
  }

  &__stack-class {
    margin-top: 0.25em;
  }
}
