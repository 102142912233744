@use "styles/variables" as *;

.bp-project-list-import-selector {
  background-color: $surface-02;

  &__result-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__result-text {
    color: $on-surface-light-secondary;
    font-size: 1.125rem;
    line-height: 2rem;
  }
}
