@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-output-title {
  padding-bottom: 5pt;
  margin-bottom: 24pt;
  border-bottom: 0.75pt solid $border-03;

  font-size: 12pt;
  font-weight: 400;
  color: $belimo-orange;
}
