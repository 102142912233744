@use "styles/variables" as *;

.bp-user-list-header {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.5rem 1rem;
  background-color: $surface-gray;

  &__icon {
    display: flex;
    flex-shrink: 0;
    width: 1.5rem;
    font-size: 1.5rem;
  }

  &__checkbox {
    --bp-checkbox-radius: 0;
    --bp-checkbox-border-width: 1px;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
    padding: 0;
    margin: 0 auto 0 0;
    text-align: start;
    background-color: transparent;
    cursor: pointer;
  }
}
