@import "styles/variables";
@import "styles/mixins";

.bp-report-document-page-hvac {
  width: 100%;
  height: 100%;

  &__title {
    font-size: 12pt;
    line-height: 18pt;
    margin-bottom: 12pt;
  }

  &__section {
    margin-bottom: 12pt;

    &:last-of-type {
      margin: 0;
    }
  }

  &__section-efficiency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.75pt solid $border-03;
    padding: 6pt 12pt;
    margin-bottom: 9pt;
  }

  &__section-title {
    flex: 1 1 0;

    font-size: 7.5pt;
    line-height: 18pt;
  }

  &__section-rating {
    flex: 0 0 auto;
    margin-left: 5pt;
    font-size: 12pt;
  }

  &__details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12pt;
  }

  &__details-icon {
    font-size: 15pt;
  }

  &__detail {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 6pt;
    max-width: 45%;
  }

  &__detail-state {
    font-size: 9pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
  }

  &__detail-title {
    font-size: 7.5pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-medium;
  }

  &__detail-description {
    font-size: 7.5pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-light;
  }

  &__detail-comment {
    background-color: $surface-02;
    padding: 6pt 12pt;
    font-size: 7.5pt;
    line-height: 12pt;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-light;
    white-space: pre-line;
    overflow: hidden;
  }
}
