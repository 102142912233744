@use "styles/variables" as *;

.bp-project-list-pager {
  .cds--pagination-nav__page {
    color: $on-surface-light-tertiary;

    &:hover {
      color: $on-surface-light-tertiary;
    }

    &:focus {
      outline: none;
    }
  }

  .cds--pagination-nav__page.cds--pagination-nav__page--active {
    color: $on-surface-light-primary;

    &:hover {
      background-color: $surface-03;
      text-decoration: none;
    }

    &:focus {
      background-color: $surface-03;
      outline: none;
    }

    &::after {
      content: none;
    }
  }
}
