// colors
$belimo-orange: #ff7c0e;
$belimo-orange-carbon: #f60;
$belimo-orange-carbon-hovered: #d94f00;
$interaction-primary: #f60;
$interaction-primary-hover: #d94f00;
$interaction-primary-disabled: #e5e5e5;
$interaction-secondary: #2c2b2b;
$interaction-secondary-hover: #000;
$interaction-tertiary-hover: #f6f6f6;
$interaction-danger: #c33224;
$interaction-danger-hover: #a02e23;
$efficiency-class-a-plus: #1c7d38;
$efficiency-class-a: #24a148;
$efficiency-class-b: #f1c21b;
$efficiency-class-c: #ff832b;
$efficiency-class-d: #c33224;
$efficiency-class-disabled: #c3c3c3;

// text colors
$on-surface-light-primary: #000;
$on-surface-light-secondary: #2c2b2b;
$on-surface-light-tertiary: #787878;
$on-surface-light-disabled: #c3c3c3;
$on-surface-light-danger: #a02e23;
$on-surface-light-bredcrumb-01: #437fa3;
$on-surface-light-bredcrumb-02: #375f80;
$on-surface-light-warning: #a08110;
$on-surface-dark-danger-light: #f8bbb8;
$on-surface-dark-success: #b6d6a4;
$on-interaction-orange: #f60;
$on-interaction-light: #fff;
$on-interaction-dark: #000;
$on-interaction-disabled: #787878;
$on-interaction-danger-light: #ffebed;
$text-warning: #f96;

// font weight
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// borders
$border-orange: #d94f00;
$border-gray: #ddd;
$border-black: #000;
$border-radius-xs: 3px;
$border-dark: #2c2b2b;
$border-danger: #c33224;
$border-05: #c4c4c4;
$border-04: #c3c3c3;
$border-03: #e5e5e5;
$border-02: #ebebeb;

// other
$background-paper-elevation-2: #e0e0e0;
$elevation-level-01: 0 1px 8px 1px #c3c3c31a;
$background-paper-elevation-level-05: #c3c3c352;
$elevation-level-02: 0 2px 6px 0 #0000001a;

// backgrounds
$surface-01: #fff;
$surface-02: #f6f6f6;
$surface-03: #ebebeb;
$surface-04: #c3c3c3;
$surface-dark: #c6c6c6;
$surface-gray: #e5e5e5;
$surface-success-02: #517d34;
$surface-success-03: #236119;
$surface-black: #000;
$surface-danger-01: #ffebed;
$surface-warning-01: #fef7df;
$surface-tag: #ffe0cc;

// report
$report-bg-gray: #f0f0f0;

// breakpoints
$xs-screen: 320px;
$sm-screen: 600px;
$md-screen: 900px;
$lg-screen: 1200px;
$xl-screen: 1536px;

// energy efficiency labels width (A, B, C, D)
$bp-energy-efficiency-label-a-width: 2.94rem; // 47px
$bp-energy-efficiency-label-b-width: 3.56rem; // 57px
$bp-energy-efficiency-label-c-width: 4.19rem; // 67px
$bp-energy-efficiency-label-d-width: 4.81rem; // 77px
