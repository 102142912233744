@use "styles/variables" as *;

.bp-report-document-page-energy {
  width: 100%;
  height: 100%;

  &__content {
    margin-top: 20pt;
  }

  &__area {
    & + & {
      margin-top: 10pt;
    }
  }

  &__comment {
    margin-top: 20pt;
  }

  &__comment-title {
    font-size: 9pt;
    line-height: 13.5pt;
  }

  &__comment-text {
    background-color: $surface-02;
    padding: 8pt 12pt;
    max-height: 75pt;
    overflow: hidden;
  }
}
