@use "styles/variables" as *;

.bp-custom-toggle {
  .cds--toggle__label {
    display: flex;
    align-items: center;
  }

  .cds--toggle__text {
    font-size: 1.125rem;
    line-height: 2rem;
    color: $on-surface-light-primary;
  }

  .cds--toggle__appearance {
    display: flex;
    column-gap: 2rem;
  }

  .cds--toggle__switch {
    flex-shrink: 0;
    border-radius: 6.25rem;
    block-size: 1.5rem;
    inline-size: 2.725rem;
    background-color: $surface-04;

    &::before {
      transition: all 150ms cubic-bezier(0.2, 0, 1, 0.9);
      block-size: 1.2rem;
      inline-size: 1.2rem;
      inset-block-start: 0.15rem;
      inset-inline-start: 0.1875rem;
    }

    &--checked {
      background-color: $on-surface-light-secondary;

      &::before {
        transform: translateX(2rem);
        inset-inline-start: -0.65rem;
      }
    }
  }
}
