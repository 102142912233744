.bp-building-info-form-modal {
  &__row {
    display: flex;
    gap: 1rem;

    &--grid {
      display: grid;
      grid-template-columns: 0.55fr 0.25fr 0.2fr;
    }
  }
}
