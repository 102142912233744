.bp-profile-page {
  &__body {
    padding: 2rem;
  }

  &__form-wrapper {
    max-inline-size: 50%;
    min-inline-size: 14.286rem;
  }
}
