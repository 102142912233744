@use "@carbon/colors" as *;
@use "styles/variables" as *;

.bp-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;

  &--sm {
    .cds--list-box__field {
      block-size: 2rem;
      padding: 0 1rem;
    }
  }

  &--md {
    .cds--list-box__field {
      block-size: 2.5rem;
      padding: 0.25rem 1rem;
    }
  }

  &--lg {
    .cds--list-box__field {
      block-size: 3rem;
      padding: 0.75rem 1rem;
    }
  }

  &--gray {
    // text and number inputs
    div.cds--number__input-wrapper > input {
      background-color: $surface-02;
    }
  }

  &--white {
    // text and number inputs
    div.cds--number__input-wrapper > input {
      background-color: $surface-01;
    }
  }

  &--end-adorned {
    // text and number inputs
    div.cds--number__input-wrapper > input[type="number"] {
      padding-right: 3.5rem;
    }
  }

  &--outlined {
    // text and number inputs
    div.cds--number__input-wrapper > input {
      border-left: 1px solid $border-04;
      border-bottom: 1px solid $border-04;

      &:hover {
        border-left: 1px solid $belimo-orange-carbon-hovered;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        border-left: none;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
        outline: none;
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    height: fit-content;
  }

  &__end-adornment {
    position: absolute;
    bottom: 0.75rem;
    right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-light;
  }

  .cds--label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: $font-weight-regular;
    color: $on-surface-light-secondary;
    margin-bottom: 0.75rem;
  }

  label.cds--label--disabled {
    color: $on-surface-light-tertiary;
  }

  .cds--text-input__invalid-icon {
    display: none;
  }

  // text and number inputs
  div.cds--number__input-wrapper > input[type="number"] {
    font-size: 1rem;
    font-weight: $font-weight-light;
    line-height: 1.125rem;

    &:hover {
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
    }

    &:focus {
      background-color: $surface-03;
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
      outline: none;
    }
  }

  // search input
  .cds--search-input {
    &:hover {
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
    }

    &:focus {
      background-color: $surface-03;
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
      outline: none;
    }
  }

  &--invalid {
    input {
      border-bottom: 1px solid $border-danger;
    }

    .cds--label {
      color: $on-surface-light-danger;
    }
  }
}
