@use "styles/variables" as *;

.bp-multiple-schemas-screen {
  padding: 1.5rem 2rem 2rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 1280px;
    margin: 0 auto;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3rem;
  }

  &__radio-group {
    .cds--radio-button-group {
      display: flex;
      gap: 1rem;
    }

    .cds--radio-button__label {
      display: flex;
      align-items: center;
    }
  }

  &__button {
    min-width: 200px;
  }

  &__button-icon {
    font-size: 1.5rem;
  }

  &__comment-badge {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    color: $belimo-orange-carbon;
  }

  &__tab-content {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    gap: 0.5rem;
  }
}
