@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;

.bp-schema-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid $background-paper-elevation-2;
  border-radius: 2px;
  background-color: transparent;

  &--selected {
    border: 2px solid $belimo-orange-carbon;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
  }

  &__description {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $on-surface-light-tertiary;
    text-align: start;
  }

  &__image-wrapper {
    display: none;
    padding: 1rem;
    margin: 0 auto;

    @include breakpoint-up("md") {
      display: flex;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    max-width: 6rem;
  }
}
