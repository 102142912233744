@use "styles/variables" as *;

.bp-screen-size-guard {
  background-color: $surface-01;

  &__modal .cds--modal-container {
    block-size: fit-content;
    position: relative;
  }

  &__modal .cds--modal-content {
    gap: 0;
    margin: auto;
  }

  &__modal-label {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  &__modal-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: $font-weight-regular;
    margin-bottom: 1.5rem;
  }

  &__modal-description {
    --cds-body-01-font-size: 1rem;
    --cds-body-01-font-weight: #{$font-weight-light};
    --cds-body-01-line-height: 1.5rem;
    color: $on-surface-light-secondary;
    margin-bottom: 1.5rem;
    padding-inline-end: 0;
  }
}
