.bp-report-form-preview-page {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 0;

  &__content {
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: 0 0;
  }
}
