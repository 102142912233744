@use "styles/variables" as *;

.bp-report-document-page-energy-thermal {
  background-color: $surface-02;
  padding: 12pt 18pt;

  &__header {
    padding: 0 0 5pt;
    border-bottom: 0.75pt solid $border-04;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18pt;
  }

  &__header-icon {
    margin-right: 3pt;
  }

  &__header-name {
    font-size: 10.5pt;
    line-height: 24pt;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 10pt;
  }

  &__section {
    flex: 1 1 50%;
  }

  &__section-title {
    font-size: 10.5pt;
    line-height: 18pt;
    margin-bottom: 12pt;
  }

  &__section-line {
    margin-bottom: 12pt;
    border-bottom: 0.75pt solid $border-04;

    &:last-of-type {
      margin: 0;
    }
  }
}
