@use "styles/variables" as *;

.bp-language {
  &__icon {
    font-size: 1.5rem;
    color: $on-surface-light-primary;
  }

  &.cds--overflow-menu-options[data-floating-menu-direction="bottom"] {
    &::after {
      inline-size: 3.5rem;
      block-size: 0.4875rem;
      inset-block-start: -0.3875rem;
    }
  }

  &__group fieldset legend {
    margin-bottom: 1rem;
  }

  &__item-wrapper {
    display: flex;
    width: 100%;
    block-size: 4rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-inline-size: none;
    padding: 1rem 1.5rem;
    text-decoration: none;
  }
}
