@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

@mixin link() {
  color: $belimo-orange-carbon-hovered;
  text-decoration: underline;
  text-underline-offset: 0.4rem;
  text-decoration-thickness: 1px;
}

.bp-login-screen {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;

  &__form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5rem 0 auto;
  }

  &__title {
    color: $on-surface-light-primary;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0.5rem;

    @include breakpoint-up("md") {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  &__description-wrapper {
    display: flex;
    gap: 0.5rem;
  }

  &__greeting {
    color: $on-surface-light-secondary;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
  }

  &__form-error {
    font-size: 0.75rem;
    color: $red-60;
    margin-bottom: 2rem;
  }

  &__form-help {
    font-size: 1rem;
    line-height: 1.5rem;
    outline: none;
  }

  &__form-extra {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__field {
    &--email {
      margin-bottom: 1rem;
    }
  }

  &__button-submit {
    width: 100%;
    max-inline-size: none;
    max-block-size: none;
    margin-bottom: 2rem;
  }

  &__button-icon {
    font-size: 1.5rem;
  }

  &__terms-and-privacy {
    margin-block-end: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__form-help-link {
    @include link;

    cursor: pointer;

    &:is(:hover, :focus) {
      color: $belimo-orange-carbon-hovered;
    }

    &:visited {
      color: $belimo-orange-carbon-hovered;

      &:is(:hover, :focus) {
        color: $belimo-orange-carbon-hovered;
      }
    }
  }

  &__language-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-right: auto;
  }

  &__language-switch {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    appearance: none;
    cursor: pointer;

    @include link;

    font-size: 1rem;
    line-height: 1.5rem;

    &--selected {
      color: $on-surface-light-primary;
      text-decoration: none;
    }
  }

  &__request-access-button {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5rem;

    @include link;

    &:hover {
      color: $belimo-orange-carbon-hovered;
    }
  }
}
