@use "styles/variables" as *;

.bp-project-list-sort-field {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  &,
  &:active,
  &:visited {
    color: $on-surface-light-secondary;
    text-decoration: none;
  }

  &:hover {
    color: $belimo-orange-carbon;
  }

  &__title {
    flex: 0 1 auto;
  }
  &__indicator {
    flex: 0 0 auto;
    margin-left: 4px;
    line-height: 0;
  }
}
