@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-schema-canvas {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  touch-action: none;
  user-select: none;

  &-modal {
    .cds--modal-scroll-content {
      mask-image: none;
      background-color: $surface-02;
      border-top: 1px $border-03 solid;
    }

    &--fullscreen {
      .cds--modal-container {
        max-block-size: none;
        width: 100vw;
        height: 100vh;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.25rem;
  }

  &__control-group {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__button {
    color: $on-surface-light-primary;
    background-color: $surface-01;

    &:hover {
      opacity: 1;
    }

    &--zoom {
      font-size: 1.125rem;

      &:hover {
        cursor: auto;
        color: $on-surface-light-primary;
      }
    }
  }

  &__zoom-wrapper {
    --cds-tooltip-padding-block: 1px;
  }

  &__zoom {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    line-height: 2rem;
    background-color: $surface-01;
    z-index: 0;
    height: 100%;
    padding: 0 0.25rem;
  }

  &__wrapper {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }

  &__content {
    position: relative;
    overflow: hidden;
    transform-origin: left top;
  }
  &__hint {
    position: absolute;
    left: 1px;
    bottom: 1px;
    z-index: 1;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $on-surface-light-tertiary;
    background-color: $surface-01;
  }
  &__chart {
    pointer-events: none;
  }
  &__layer {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  &__layer-content {
    width: 100%;
    height: 100%;
    transform-origin: left top;
  }
}
