@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-result-savings-system {
  &__assessments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $surface-02;
    padding: 1rem;

    @include breakpoint-up("lg") {
      flex-direction: row;
    }
  }

  &__assessment {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem;
    background-color: $surface-01;
    font-size: 1rem;
  }

  &__assessment-stack {
    font-size: 1.5rem;
  }

  &__assessment-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }

  &__assessment-title {
    text-align: center;
  }

  &__assessment-description {
    font-weight: 300;
    text-align: center;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.7rem;

    @include breakpoint-up("lg") {
      flex-direction: row;
    }
  }

  &__saving-area {
    display: flex;
    justify-content: space-between;
  }

  &__saving-description {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
  }

  &__saving-value {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
  }
}
