@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-form-assessment-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: $surface-02;

  &__divider {
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid $border-03;
    border-bottom: none;
    width: 100%;
    margin: 0;
  }

  &__tab-content {
    display: flex;
    gap: 0.25rem;
  }
  &__tabs-content {
    padding: 1rem;
    background-color: $surface-02;
  }
}
