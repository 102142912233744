@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

.bp-password-reset-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__field {
    & + & {
      margin-top: 1rem;
    }
  }
  &__submit {
    margin-top: 2rem;
  }

  &__submit-icon {
    font-size: 1.5rem;
  }
}
