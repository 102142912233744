@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-schema-edit-screen {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
  }

  &__buttons {
    display: flex;
    gap: 0.75rem;

    .cds--btn .cds--btn__icon {
      position: absolute;
      flex-shrink: 0;
      block-size: 1.5rem;
      inline-size: 1.5rem;
      inset-block-start: 0.65rem;
      inset-inline-end: 1rem;
      margin-block-start: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
  }

  &__content-button.cds--btn {
    color: $on-surface-light-primary;
    font-size: 1.5rem;
  }

  &__content-tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content-actions {
    display: flex;
  }

  &__tab {
    &--new.cds--tabs__nav-link {
      display: flex;
      margin: 0;
      padding: 1rem;
      background-color: $belimo-orange-carbon;
      border-block-end: 2px solid $belimo-orange-carbon;
    }
  }
}
