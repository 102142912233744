.bp-feedback-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 0;
  right: 0;
  top: 50%;

  &__button.bp-custom-button {
    transform: rotate(270deg) translateY(-50%);
  }

  &__button-icon {
    font-size: 1.5rem;
  }
}
