@use "styles/variables" as *;

.bp-share-project-share-form {
  display: flex;
  gap: 1rem;
  width: 100%;

  &__input-hint {
    color: $on-surface-light-secondary;
  }

  &__button {
    min-width: 262px;
  }
}
