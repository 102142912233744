@import "styles/variables";
@import "styles/mixins";

.bp-report-document-page-savings {
  width: 100%;
  height: 100%;

  &__header {
    font-size: 12pt;
    line-height: 18pt;
    margin-bottom: 12pt;
  }

  &__content {
    display: flex;
    gap: 24pt;
  }

  &__efficiency {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    gap: 24pt;
    margin-bottom: 12pt;
  }

  &__savings {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
  }

  &__efficiency-column {
    padding: 6pt 12pt 12pt;
    min-width: 0;
  }

  &__efficiency-header {
    font-size: 10.5pt;
    line-height: 24pt;
    padding-bottom: 4pt;
    margin-bottom: 12pt;
  }

  &__efficiency-periods {
    display: flex;
    gap: 70pt;
    font-size: 9pt;
    line-height: 24pt;
    color: $on-surface-light-secondary;
    width: fit-content;
  }

  &__efficiency-stacks {
    display: flex;
    gap: 65pt;
    transform: scale(1);
    width: fit-content;
    font-size: 8pt;
  }

  &__efficiency-stack {
    display: flex;
    flex-direction: column;
    gap: 12pt;
    transform: scale(1);
    width: fit-content;
    font-size: 8pt;
  }

  &__overall-efficiency-periods {
    display: flex;
    justify-content: flex-end;
    gap: 27pt;
    font-size: 9pt;
    line-height: 24pt;
    color: $on-surface-light-secondary;
    margin: 0 18pt 6pt 0;
  }

  &__overall-efficiency-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.75pt solid $border-03;
    padding: 6pt 12pt;
    margin-bottom: 6pt;

    &:last-of-type {
      margin: 0;
    }
  }

  &__overall-efficiency-description {
    display: flex;
    gap: 3pt;
    align-items: center;
  }

  &__overall-efficiency-icon {
    font-size: 18pt;
  }

  &__overall-efficiency-result {
    display: flex;
    gap: 3pt;
    align-items: center;
  }

  &__potential-savings {
    display: flex;
    flex-direction: column;
    gap: 12pt;
    margin-bottom: 12pt;
    font-size: 13pt;
  }

  &__potential-saving-area {
    display: flex;
    justify-content: flex-start;
  }

  &__potential-saving-name {
    margin-right: auto;
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
  }

  &__potential-saving-value {
    margin-left: 2pt;
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-bold;
    color: $on-surface-light-secondary;
  }

  &__potential-saving-unit {
    margin-left: 2pt;
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-bold;
    color: $on-surface-light-secondary;
  }

  &__about {
    font-size: 7.5pt;
    line-height: 12pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
    column-count: 2;
    column-gap: 24pt;
    overflow: hidden;
    white-space: pre-line;

    a {
      color: $belimo-orange-carbon;
      text-decoration: none;
    }
  }
}
