@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;

.bp-project-list-toolbar {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2rem 2rem 0;
  background-color: $surface-01;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    font-size: 2.5rem;
    line-height: 3rem;
    color: $on-surface-light-primary;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__controls-search {
    display: flex;
    flex-grow: 1;
  }

  &__controls-actions {
    display: flex;
    flex-direction: row;
  }

  &__controls-icon {
    font-size: 1.5rem;
  }

  &__controls-button.cds--btn--ghost {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: $on-surface-light-primary;
    font-size: 1.5rem;

    &:hover {
      color: $on-surface-light-primary;
    }

    &--loading {
      &.cds--btn--ghost svg {
        fill: transparent;
      }
    }
  }

  &__popover {
    position: relative;
  }

  &__popover-content {
    min-width: 576px;
  }
}
