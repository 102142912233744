@import "styles/variables";
@import "styles/mixins";

.bp-hvac-result-energy-prices-view {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &--horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__input {
    flex-grow: 1;
  }
}
