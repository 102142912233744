@use "styles/variables" as *;

.bp-organization-settings {
  padding: 1.5rem 2rem;

  &__content {
    display: grid;
    gap: 1rem;
    align-items: flex-start;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    grid-auto-rows: minmax(100px, auto);

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
  &__contacts {
    grid-column: 1;
    grid-row: 1;
  }
  &__office {
    grid-column: 1;
    grid-row: 2;
  }
  &__office-contents {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  &__logo {
    grid-column: 2;
    grid-row: 1 / 3;
  }

  &__row {
    display: flex;
    gap: 1rem;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &-title {
      color: $on-surface-light-secondary;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }

    &-info {
      color: $on-surface-light-tertiary;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-light;
    }
  }
}
