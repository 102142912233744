@import "styles/variables";
@import "styles/mixins";

.bp-report-document-page-energy-ventilation {
  background-color: $surface-02;
  padding: 18pt;

  &__header {
    padding: 0 0 5pt;
    border-bottom: 0.75pt solid $border-04;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18pt;
  }

  &__header-icon {
    font-size: 18pt;
    margin-right: 3pt;
  }

  &__header-name {
    font-size: 10.5pt;
    line-height: 24pt;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 10pt;
  }

  &__content-line {
    flex: 1 1 33%;
    border-bottom: 0.75pt solid $border-04;
  }
}
