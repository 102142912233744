@use "styles/variables" as *;

.bp-custom-breadcrumb-item {
  --cds-link-primary: $on-surface-light-bredcrumb-01;
  --cds-link-primary-hover: $on-surface-light-bredcrumb-02;

  font-size: 1rem;
  line-height: 1.5rem;
  color: $on-surface-light-bredcrumb-01;
  text-decoration: none;

  &:hover {
    color: $on-surface-light-bredcrumb-02;
  }
}
