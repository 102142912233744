@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-onboarding-modal {
  &__body {
    display: flex;
    flex-direction: column;

    .bp-body-text {
      min-height: 4rem;
    }
  }

  &__title-body-text {
    text-transform: uppercase;
  }

  &__image {
    flex-shrink: 1;
    width: 100%;
    min-height: 0;
    margin: 0 auto 1.5rem;
  }

  &__button-icon {
    font-size: 1.5rem;
  }
}
