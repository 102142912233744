@use "styles/variables" as *;

.bp-checkbox-field {
  flex: 0 0 auto;

  &.cds--form-item.cds--checkbox-wrapper {
    display: flex;
    justify-content: center;
    flex-grow: 0;
    margin-block-end: 0;
    margin-block-start: 0;
  }

  &.cds--checkbox-wrapper:last-of-type {
    margin-block-end: 0;
  }

  &:first-of-type {
    margin-block-start: 0;
  }

  .cds--checkbox-label {
    padding-inline-start: 1.375rem;
    padding-block-start: 0;
    padding-block-end: 0.25rem;

    .cds--checkbox-label-text {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-light;
      color: $on-surface-light-primary;
      padding-inline-start: 0.5rem;
    }

    &::before {
      inset-block-start: 0.07rem;
      inline-size: 1.375rem;
      block-size: 1.375rem;
      margin: 0;
      border-width: 2px;
    }

    &::after {
      block-size: 0.45rem;
      inline-size: 0.8rem;
      inset-block-start: 0.3rem;
      inset-inline-start: 0.35rem;
    }
  }
}
