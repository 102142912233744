@use "styles/variables" as *;

.bp-hvac-form-setup-option {
  width: 100%;
  display: flex;
  gap: 1.5rem;
  padding: 1rem 2rem 1rem 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &--with-comment {
    text-wrap: wrap;
  }

  &__label {
    display: flex;
    flex-shrink: 0;
    font-size: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  &__title-wrapper {
    display: flex;
    gap: 2rem;
  }

  &__title {
    font-size: 1.125rem;
    line-height: 2rem;
    width: 80%;
  }

  &__comment {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
    max-width: 100%;
  }
}
