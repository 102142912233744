@use "styles/variables" as *;

.bp-form-section {
  inline-size: 100%;
  max-inline-size: 1280px;
  margin-inline: auto;
  padding: 2rem 5rem;

  background-color: $surface-01;
  border: 1px solid $border-02;
  box-shadow: $elevation-level-01;

  &__title {
    font-size: 2rem;
    font-weight: $font-weight-regular;
    line-height: 2.5rem;

    color: $on-surface-light-secondary;
  }
}
