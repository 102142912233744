.bp-app-layout {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  min-block-size: 100vh;

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    inline-size: 100%;
    block-size: 100%;
  }
}
