@use "@carbon/react/scss/breakpoint" as *;
@import "styles/variables";
@import "styles/mixins";

.bp-hvac-form-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 0;
  cursor: pointer;

  &--disabled {
    cursor: auto;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
  }

  &__title {
    text-align: start;
    flex-grow: 1;
    padding: 1rem;
    font-weight: $font-weight-regular;
    color: $on-surface-light-primary;
    font-size: 1rem;
    line-height: 1.5rem;
    overflow-wrap: break-word;

    &--copy {
      padding-left: 2.75rem;
    }
  }

  &__label {
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 125px;
    min-height: 3.5rem;
    align-items: center;
    padding: 0 0 0 1rem;
    border-left: 1px solid $border-03;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    min-height: 3.5rem;
    height: 100%;
  }

  &__title,
  &__label,
  &__controls {
    transition: opacity 200ms ease;
  }

  &__menu-row-icon {
    font-size: 2rem;
  }
}
