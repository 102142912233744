@use "styles/variables" as *;

.bp-feedback-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  &__rating {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: $font-weight-regular;
    color: $on-surface-light-secondary;
  }

  &__button-icon {
    font-size: 1.5rem;
  }
}
