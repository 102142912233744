@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

.bp-file-field {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239E9E9EFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e");

  &--white {
    background-color: $surface-01;
  }

  &--gray {
    background-color: $surface-02;
  }

  &--filled {
    width: 360px;
    height: 324px;
    border: 1px solid $on-surface-light-tertiary;
  }

  &--disabled {
    opacity: 0.5;
  }

  &--invalid {
    color: $red-60;
    border-color: $red-60;
  }

  &__placeholder {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-tertiary;
    white-space: pre-line;
    text-align: center;
  }

  &__hint {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $on-surface-light-tertiary;
  }

  &__icon-wrapper {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  &__cancel-button {
    display: flex;
    align-items: center;
    padding: 0;
  }
}
