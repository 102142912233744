@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-result-savings-explanation {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__tile {
    background-color: $surface-01;
    border: 1px solid $border-03;
    padding: 1.25rem;
  }

  &__savings {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__costs {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    margin: 0.5rem 0;
  }

  &__savings-text {
    color: $on-surface-light-primary;
  }

  &__warning {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;

    font-size: 1rem;
    color: $on-surface-light-danger;
  }

  &__disclaimer-text {
    white-space: pre-line;
  }
}
