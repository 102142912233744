@use "styles/variables" as *;

.bp-overflow-menu {
  position: relative;
  display: flex;
  width: fit-content;

  &--opened {
    .bp-overflow-menu__list {
      visibility: visible;
      box-shadow: 0 2px 6px 0 #0000001a;
    }

    .bp-overflow-menu__button {
      background-color: $surface-03;
    }
  }

  &--interactive {
    .bp-overflow-menu-item {
      cursor: pointer;

      &:hover {
        background-color: $surface-03;
      }
    }
  }

  &__button-icon {
    font-size: 1.5rem;
  }

  &__list {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: $surface-01;
    visibility: hidden;
    z-index: 1;
    min-width: 100%;
    max-height: 590px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-item {
    display: flex;
    flex-wrap: nowrap;
    padding: 0.75rem 1.5rem;
    cursor: auto;
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;

    &--selected {
      background-color: $surface-03;
    }
  }
}
