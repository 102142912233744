@import "styles/variables";
@import "styles/mixins";

.bp-output-page {
  width: 210mm;
  height: 297mm;

  position: relative;
  padding: 15mm 15mm 10mm;

  color: #000;
  background-color: #fff;

  font-size: 11pt;
  font-weight: $font-weight-regular;
  font-family: "Roboto", sans-serif;
  font-variant-ligatures: none;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &--zh {
    font-family: "NotoSans", sans-serif;
  }

  &__body {
    flex: 1 1 auto;
    min-height: 0;
    margin-bottom: auto;
  }

  &__pager {
    position: absolute;
    left: 10mm;
    right: 10mm;
    bottom: 5mm;

    font-size: 10pt;
    text-align: right;
  }

  a,
  a:visited,
  a:active,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  b,
  strong {
    font-weight: $font-weight-bold;
  }

  img {
    // default `img` styles changed in Goggle Chrome 108 (it breaks PDF output):
    // overflow-clip-margin: content-box;
    // overflow: clip;
    overflow: hidden;
  }
}
