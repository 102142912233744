@import "styles/variables";
@import "styles/mixins";

.bp-energy-efficiency-transition {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__actual,
  &__future {
    flex: 0 0 auto;
  }
  &__arrow {
    display: flex;
    flex: 0 0 auto;
    margin: 0 0.25em;
  }
}
