@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-project-create-modal {
  &__header {
    padding: 2.5rem 2.5rem 1rem;
    margin: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 16px 2.5rem 2.5rem;
  }

  &__button-icon {
    font-size: 1.5rem;
  }
}
