@use "styles/variables" as *;

.bp-basic-info-form-modal {
  &__file-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__file-label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $on-surface-light-secondary;
  }

  &__file-image-container {
    margin: auto;
    width: 50%;
  }
}
