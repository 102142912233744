@use "styles/variables" as *;

.bp-announcements {
  &__icon {
    position: relative;
    font-size: 1.5rem;
  }

  &__badge {
    position: absolute;
    top: 0.05rem;
    right: 0.05rem;
    font-size: 0.625rem;
    color: $interaction-danger;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    min-width: 360px;
  }

  &__list-header {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
    color: $on-interaction-dark;
    border-bottom: 1px solid $border-03;
  }

  &__item-wrapper {
    block-size: auto;

    &:first-of-type {
      .bp-notification-center__item {
        padding: 1rem 1rem 0.75rem;
      }
    }

    &:last-of-type {
      .bp-notification-center__item {
        padding: 0.75rem 1rem 1rem;
      }
    }
  }

  &__item {
    &.bp-custom-button.cds--btn {
      padding: 1rem;

      &:hover {
        background-color: $surface-01;
      }
    }
  }

  &__notification {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  &__notification-header {
    display: flex;
    justify-content: space-between;
  }

  &__notification-title {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $on-interaction-dark;
    margin-right: 1rem;
  }

  &__notification-details {
    display: flex;
    align-items: center;
    height: fit-content;
  }

  &__notification-date {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-right: 0.25rem;
    color: $on-surface-light-tertiary;
  }

  &__notification-badge {
    font-size: 0.625rem;
    color: $belimo-orange-carbon;
  }

  &__notification-description {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: $font-weight-light;
    color: $on-surface-light-tertiary;
  }
}
