@use "styles/variables" as *;

.bp-project-list-import-data-table {
  &__tab {
    display: flex;
    gap: 0.25rem;
  }

  &__table {
    width: 100%;
  }

  .cds--data-table tbody tr &__cell {
    &--status {
      inline-size: 7rem;
    }

    &--checkbox {
      inline-size: 1.5rem;
    }
  }

  &__error-text {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &__invalid-text {
    font-size: 1rem;
    line-height: 1.25rem;
    color: $interaction-danger;
  }

  &__valid-text {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
  }

  &__row-text {
    font-size: 1rem;
    line-height: 1.25rem;
    white-space: nowrap;
    color: $on-surface-light-secondary;

    &--number {
      text-align: end;
    }
  }
}
