.bp-rating-field {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &--error {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #da1e28;
  }
}
