@use "styles/variables" as *;

.bp-admin-layout {
  &__header-wrapper {
    padding: 1.5rem 2rem 0;
    background-color: $surface-01;
  }

  &__header-content {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__header {
    display: grid;
    grid:
      "breadcrumbs actions" auto
      "title       actions" auto /
      1fr auto;
    column-gap: 2rem;
    inline-size: 100%;
    max-inline-size: 1280px;
    margin-inline: auto;
    padding: 0 0 1rem;
  }

  &__header-title {
    grid-area: title;
    place-self: flex-end flex-start;
    margin-top: 1rem;

    color: $on-surface-light-primary;
    font-size: 2.5rem;
    font-weight: $font-weight-regular;
    line-height: 3rem;
  }

  &__header-actions {
    grid-area: actions;
    place-self: flex-start flex-end;
  }

  &__header-extensions {
    display: flex;
    justify-content: flex-end;
  }
}
