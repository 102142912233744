@use "styles/variables" as *;

.bp-horizontal-divider {
  display: flex;
  flex-shrink: 0;
  border: none;
  border-top: 1px solid $border-03;
  width: 100%;
  margin: 0;
}
