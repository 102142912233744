@import "styles/variables";
@import "styles/mixins";

.bp-schema-gesture {
  width: 100%;
  height: 100%;

  touch-action: none;
  user-select: none;

  &__scale {
    transform-origin: left top;
  }
}
