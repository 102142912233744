@use "styles/variables" as *;

.bp-project-list-item-grid-view {
  display: flex;
  flex-direction: column;
  height: 31rem;
  background-color: $surface-01;
  text-decoration: none;
  color: $on-surface-light-primary;
  border: 1px solid $border-02;

  &__header {
    flex: 0 0 auto;
    padding: 1rem 1.5rem;
  }
  &__header-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  &__header-address {
    margin-top: 0.25rem;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__text-limited {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__cover {
    flex: 1 1 0;
    min-height: 0;
  }
  &__cover-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  &__cover-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    flex: 0 0 auto;
    padding: 1rem 1.5rem;
  }
  &__info-updated {
    display: flex;
    justify-content: flex-start;

    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
  }
  &__info-rating {
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }

  &__actions {
    flex: 0 0 auto;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    border-top: 1px solid $border-02;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__actions-owner {
    flex: 1 1 0;
    min-width: 0;
  }
  &__actions-menu {
    flex: 0 0 auto;
  }

  &__ownership-tag-tooltip {
    max-inline-size: 100%;
  }

  &__ownership-tag {
    padding: 0.25rem 0.75rem;
    height: 1.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    border: none;
    border-radius: 6.25rem;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--shared {
      color: $belimo-orange-carbon-hovered;
      background-color: $surface-tag;
    }

    &--owned {
      color: $on-surface-light-tertiary;
      background-color: $surface-03;
    }
  }
}
