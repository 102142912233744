@import "styles/variables";
@import "styles/mixins";

.bp-hvac-result-energy-electrical-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__label {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
}
