@import "styles/variables";
@import "styles/mixins";

.bp-schema-container-preview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background-color: $surface-01;
  padding: 1.5rem;

  &__view {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
  }
}
