@use "@carbon/react" with (
  $use-flexbox-grid: true
);
@use "@carbon/react/scss/themes";
@use "@carbon/react/scss/theme";

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline: 1px solid $belimo-orange-carbon;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  min-block-size: 100vh;
  font-size: 14px;

  @include screen($sm-screen) {
    font-size: 16px;
  }

  /* scrollbar total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

html {
  text-size-adjust: 100%;
}

body {
  background-color: $surface-02;
  padding-right: var(--scroll-bar-width, 0);

  .bp-feedback-button {
    right: var(--scroll-bar-width, 0);
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
  min-block-size: 100vh;
  @include theme.theme;

  // disable the Carbon's blue outline for focused components
  --cds-focus: transparent;
}

sub,
sup {
  font-size: 0.6em;
  position: relative;
}

sub {
  vertical-align: bottom;
  bottom: -0.3em;
}

sup {
  vertical-align: top;
  top: -0.3em;
}

b {
  font-weight: 500;
}

i {
  font-style: italic;
}

code {
  font-family:
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue";
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*="language-"])::before,
code:not([class*="language-"])::after {
  content: "`";
}
code[class*="language-"] {
  padding: 0;
}

/* stylelint-disable selector-class-pattern */
#nprogress {
  .bar {
    background: $belimo-orange !important;
    z-index: 1231 !important;
  }
  .peg {
    box-shadow: 0 0 10px $belimo-orange, 0 0 5px $belimo-orange !important;
  }
}
/* stylelint-enable selector-class-pattern */
