@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-error-message {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: $surface-01;

  &__block {
    display: flex;
    flex-direction: column;
    will-change: transform;
    gap: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: $on-surface-light-secondary;
    font-weight: 500;
    border-bottom: 1px solid $on-surface-light-secondary;
  }

  &__code {
    overflow: scroll;
    padding: 1em;
    background-color: #eee;
  }

  &__previous {
    margin-top: 2rem;
    padding-left: 2rem;
  }
}
