@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-feedback-modal {
  &__body {
    display: flex;
    flex-direction: row;
    padding: 2.5rem 2.5rem 1rem;
    gap: 5rem;
  }

  &__slogan {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
  }

  &__form {
    display: flex;
    flex-grow: 1;
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
  }
}
