@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  padding: 0 2rem;
  border-bottom: 1px solid $border-03;
  background-color: $surface-01;

  &__branding {
    display: flex;
    gap: 2.5rem;
  }

  &__logo {
    height: 2.5rem;
    margin: 0.5rem 0;
    position: relative;
  }

  &__controls {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__onboarding-icon {
    font-size: 1.5rem;
  }
}
