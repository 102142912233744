.bp-project-list-filter-group {
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  margin-bottom: 0.5rem;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__label {
    width: 100%;
  }

  &__buttons {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
}
