@import "styles/variables";
@import "styles/mixins";

.bp-energy-price-unit {
  white-space: nowrap;

  &__div {
    margin: 0 0.1em;
  }
}
