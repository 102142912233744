@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-carbon-intensity-unit {
  white-space: nowrap;

  &__div {
    margin: 0 0.1em;
  }
}
