@use "styles/variables" as *;

.bp-tile-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: $surface-01;
  width: 100%;
  border: 1px solid $border-02;
  box-shadow: $elevation-level-01;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
