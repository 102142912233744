@use "styles/variables" as *;

.bp-autocomplete-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;

  --cds-border-strong: #{$border-04};

  &:hover {
    --cds-border-strong: #{$belimo-orange-carbon-hovered};
  }

  &--sm {
    .cds--list-box .cds--list-box__field input {
      block-size: 2rem;
      padding: 0 4.5rem 0 1rem;
    }
  }

  &--md {
    .cds--list-box .cds--list-box__field input {
      block-size: 2.5rem;
      padding: 0.5rem 4.5rem 0.5rem 1rem;
    }
  }

  &--lg {
    .cds--list-box .cds--list-box__field input {
      block-size: 3rem;
      padding: 0.75rem 4.5rem 0.75rem 1rem;
    }
  }

  &--gray {
    .cds--list-box .cds--list-box__field input {
      background-color: $surface-02;
    }
  }

  &--white {
    .cds--list-box .cds--list-box__field input {
      background-color: $surface-01;
    }
  }

  &--outlined {
    .cds--list-box input {
      border-left: 1px solid $border-04;
      border-bottom: 1px solid $border-04;

      &:hover {
        border-left: 1px solid $belimo-orange-carbon-hovered;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        border-left: none;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
        outline: none;
      }
    }
  }

  &--invalid {
    .cds--label {
      color: $on-surface-light-danger;
    }
  }

  &--required {
    .cds--list-box__selection {
      visibility: hidden;
    }
  }

  .cds--label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: $font-weight-regular;
    color: $on-surface-light-secondary;
    margin-bottom: 0.75rem;
  }

  .cds--label.cds--label--disabled {
    color: $on-surface-light-tertiary;
  }

  .cds--combo-box {
    height: auto;
    border: none;
    block-size: fit-content;
    max-block-size: fit-content;

    --cds-body-compact-01-font-size: 1rem;
    --cds-body-compact-01-font-weight: #{$font-weight-light};
  }

  .cds--list-box__field {
    height: auto;
  }

  .cds--list-box .cds--list-box__field input:focus {
    background-color: $surface-03;
    outline: none;
  }
}
