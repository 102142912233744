@import "styles/variables";
@import "styles/mixins";

.bp-schema-container-select {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;

  &__grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    padding: 0 0 2rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    height: 100%;
    background-color: $surface-01;
    border: 1px solid $border-03;
    padding: 1.5rem;
    cursor: pointer;

    &:hover {
      border: 1px solid $interaction-primary;
    }

    &--selected {
      border-color: #f60;
    }
  }

  &__item-title {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
    text-align: start;
    overflow-wrap: break-word;
  }

  &__item-description {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $on-surface-light-secondary;
    text-align: start;
  }

  &__label {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
