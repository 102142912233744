@use "styles/variables" as *;

.bp-divider {
  inline-size: 1px;
  block-size: 1px;

  background-color: $border-03;

  &--horizontal {
    inline-size: auto;
  }

  &--vertical {
    block-size: auto;
  }
}
