@use "styles/variables" as *;

.bp-report-document-page-energy-value {
  &__title {
    font-size: 9pt;
    line-height: 13.5pt;
    height: 15pt;
    color: $on-surface-light-secondary;
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 7.5pt;
    line-height: 18pt;
    height: 19pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
  }
}
