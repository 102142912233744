@use "styles/variables" as *;
@use "styles/mixins" as *;
@use "@carbon/react/scss/breakpoint" as *;

.bp-evaluation-results-charts {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
    color: $on-surface-light-secondary;
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
  }

  &__divider {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
  }

  &__grid-title {
    display: flex;
    gap: 0.5rem;
  }

  &__tooltip {
    .cds--popover-content {
      max-inline-size: 30rem;
    }
  }

  &__amount {
    display: flex;
    font-size: 1.7rem;
  }

  &__tile {
    padding: 2rem;
    background-color: $surface-01;
    border: 1px solid $border-03;
  }

  &__table {
    margin-top: 2rem;
  }

  &__table-cell {
    padding: 0.5rem 1rem;
    border-bottom: solid 1px $border-05;
    text-align: end;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  th {
    padding: 0.5rem 1rem;
    text-align: right;
    border-bottom: solid 1px $border-05;
    font-weight: bold;
    background-color: #eee;
  }
}
