@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

.bp-login-layout {
  display: flex;
  height: 100%;

  &__logo {
    height: 2.5rem;
    margin-right: auto;
  }

  &__column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    height: 100%;

    &--gray {
      width: 60%;
      background-color: #f5f5f5;
    }

    &--white {
      width: 40%;
      background-color: #fff;
    }
  }

  &__contents {
    margin: 5rem 0 auto;
  }

  &__company {
    display: flex;
    flex-direction: column;

    max-width: 778px;
    margin: 0 auto;
  }

  &__brand {
    color: $on-surface-light-primary;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;

    @include breakpoint-up("md") {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  &__super {
    color: $belimo-orange-carbon;
    margin-left: $spacing-03;
  }

  &__description {
    color: $on-surface-light-secondary;
  }

  &__picture {
    display: flex;
    object-fit: fill;
    object-position: center;
    max-width: 100%;
    max-height: 512px;
    margin: $spacing-05 auto;
  }
}
