@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-form-section-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 2.5rem 3.5rem;

  &__title-wrapper {
    display: flex;
    gap: 2rem;
  }

  &__title {
    flex-grow: 1;
  }

  &__button-icon {
    font-size: 1.5rem;
  }
}
