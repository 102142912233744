.bp-icon-wrapper {
  display: flex;
  flex-shrink: 0;
  width: 1em;
  height: 1em;

  svg {
    width: 100%;
    height: 100%;
  }
}
