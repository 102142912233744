@use "styles/variables" as *;

.bp-users-list-body {
  display: flex;
  padding: 1.5rem 2rem;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
  }

  &__selections {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: $belimo-orange-carbon;
    padding: 0 1rem;
  }

  &__selection-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__selection-divider {
    border-left: 1px solid $border-black;
    width: 1px;
    height: 1rem;
  }

  &__actions {
    display: flex;
    width: 100%;
  }

  &__icon {
    font-size: 1.5rem;
  }
}
