.bp-project-list-header {
  display: contents;
  margin: 0;
  padding: 0;

  &__column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-right: 2rem;

    &--icon {
      align-items: center;
    }

    &--project {
      gap: 4px;
    }
  }
}
