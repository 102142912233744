@use "styles/variables" as *;

.bp-dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 100px;
  flex: 1 1 0;

  &--disabled {
    user-select: none;
  }

  &__label {
    text-wrap: nowrap;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $on-surface-light-secondary;

    &--disabled {
      color: $on-surface-light-disabled;
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__value {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
    padding: 0.75rem 1.5rem;

    &:hover {
      background-color: $surface-02;
      border-color: $belimo-orange-carbon-hovered;
    }

    &:focus {
      background-color: $surface-02;
      border-color: $belimo-orange-carbon-hovered;
    }

    &:focus-visible {
      outline-offset: -1px;
    }

    &--single {
      cursor: auto;
    }

    &--outlined {
      border: 1px solid $border-04;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
      }
    }

    &--shaded {
      background-color: $surface-02;
    }

    &--disabled {
      cursor: auto;
      color: $on-surface-light-disabled;

      &:hover {
        border-color: $border-04;
      }

      &:focus {
        border-color: $border-04;
      }
    }

    &--sm {
      height: 2rem;

      & + .bp-dropdown__list {
        top: 2rem;

        .bp-dropdown__option {
          height: 2rem;
        }

        .bp-dropdown__option-icon {
          font-size: 1.5rem;
        }
      }
    }

    &--md {
      height: 2.5rem;

      & + .bp-dropdown__list {
        top: 2.5rem;

        .bp-dropdown__option {
          height: 2.5rem;
        }
      }
    }

    &--lg {
      height: 3rem;

      & + .bp-dropdown__list {
        top: 3rem;

        .bp-dropdown__option {
          height: 3rem;
        }
      }
    }

    &--xlg {
      height: 3.5rem;

      & + .bp-dropdown__list {
        top: 3.5rem;

        .bp-dropdown__option {
          height: 3.5rem;
        }
      }
    }
  }

  &__value-icon {
    margin-left: auto;
    font-size: 1.5rem;
  }

  &__list {
    flex-direction: column;
    min-width: 100%;
    position: absolute;
    right: 0;

    z-index: 3;
    width: fit-content;
    max-height: 300px;
    overflow: hidden auto;
    box-shadow: 0 2px 6px 0 #0000001a;
    display: none;

    &--opened {
      display: flex;
    }
  }

  &__option {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    cursor: pointer;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: $surface-02;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
    text-wrap: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: $surface-03;
    }

    &--selected {
      background-color: $surface-03;
      padding: 0.5rem 1rem;
    }
  }

  &__option-icon {
    font-size: 1.5rem;
  }
}
