@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

.bp-password-reset-error {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin: 7.5rem 0 auto;

  &__title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  &__subtitle {
    margin-top: 1rem;
  }

  &__back {
    margin-top: 2rem;
  }
}
