@use "styles/variables" as *;

.bp-error-modal {
  &__body {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  &__body-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    color: $on-surface-light-primary;
    text-align: center;
    white-space: pre-line;
  }

  &__body-description {
    font-size: 1rem;
    line-height: 2rem;
    color: $on-surface-light-secondary;
    text-align: center;
    white-space: pre-line;
  }
}
