@use "styles/mixins" as *;
@use "styles/variables" as *;

.bp-hvac-form-section-copy-delete-confirmation-modal {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}
