@use "styles/variables" as *;

.bp-report-document-page-evaluation-charts {
  display: flex;
  flex-wrap: wrap;
  gap: 12pt;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 0 1 calc(50% - 6pt);
    max-width: calc(50% - 6pt);
    border: 0.75pt solid $border-03;
    padding: 12pt;
  }

  &__container-header {
    border-bottom: 0.75pt solid $border-03;
    margin-bottom: 20pt;
  }

  &__container-title {
    font-size: 10.5pt;
    line-height: 24pt;
  }

  &__container-description {
    font-size: 8.5pt;
    line-height: 14pt;
    color: $on-surface-light-secondary;
    margin-bottom: 6pt;
    font-weight: $font-weight-light;
    text-transform: uppercase;
  }

  &__container-additionals {
    display: flex;
    gap: 12pt;
    overflow: hidden;
    margin-top: 40pt;
  }

  &__table {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__table-header-row {
    display: flex;
    justify-content: space-between;
    background-color: $surface-02;
    padding: 0 15pt;
  }

  &__table-body-row {
    display: flex;
    justify-content: space-between;
  }

  &__table-header-cell {
    font-size: 7.5pt;
    line-height: 18pt;
    text-align: center;
  }

  &__table-body-cell {
    font-size: 7.5pt;
    line-height: 18pt;
    font-weight: $font-weight-light;
    text-align: center;
    min-width: 50pt;
  }

  /* stylelint-disable selector-class-pattern */
  .recharts-yAxis.yAxis,
  .recharts-xAxis.xAxis {
    text,
    tspan {
      alignment-baseline: alphabetic;
    }
  }
}
