.bp-project-list-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.5rem;

  &__icon {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
}
