@use "@carbon/react/scss/colors" as *;
@use "styles/variables" as *;

.bp-user-profile {
  display: flex;
  flex-direction: column;
  &.cds--overflow-menu-options[data-floating-menu-direction="bottom"] {
    &::after {
      inline-size: 3.5rem;
      block-size: 0.4875rem;
      inset-block-start: -0.4875rem;
    }
  }

  &__icon {
    font-size: 1.5rem;
    color: $on-surface-light-primary;
  }

  &__menu-email {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: $on-surface-light-secondary;
    padding: 1rem 1.5rem;
    width: 100%;
  }

  &__menu-item-wrapper {
    display: flex;
    width: 100%;
    block-size: 4rem;
  }

  &__menu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-inline-size: none;
    padding: 1rem 1.5rem;
    text-decoration: none;
  }

  &__menu-item-link {
    width: 100%;
    text-decoration: none;
  }

  &__menu-item-text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
    margin-right: auto;
  }

  &__menu-item-icon {
    font-size: 1.5rem;
  }

  &__content-username {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__menu-group-title {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $on-surface-light-tertiary;
    padding: 0.5rem 1.5rem;
    background-color: $surface-01;
    width: 100%;
    margin: 0.125rem 0;
  }
}
