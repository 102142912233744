@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-evaluation-screen {
  padding: 1.5rem 2rem 2rem;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    gap: 1rem;
  }
}
