@use "styles/variables" as *;

.bp-custom-tabs {
  &.cds--tabs {
    width: fit-content;
    background-color: $surface-01;

    // for tooltips
    .cds--tab--list {
      max-height: 3rem;
      overflow: visible;
    }
  }

  &__tab {
    // default tab styles
    --cds-heading-compact-01-font-size: 1rem;
    --cds-heading-compact-01-font-weight: 400;
    --cds-heading-compact-01-line-height: 1.5rem;
    --cds-text-primary: #{$on-surface-light-primary};
    --cds-layout-density-padding-inline-local: 1.5rem;
    --cds-border-interactive: #{$belimo-orange-carbon-hovered};

    // border between tabs
    --cds-border-strong: transparent;

    // unselected tabs styles
    --cds-layer-accent: #{$surface-gray};
    --cds-body-compact-01-font-size: 1rem;
    --cds-text-secondary: #{$on-surface-light-tertiary};

    // disabled tabs styles
    --cds-button-disabled: #{$surface-gray};
    --cds-text-on-color-disabled: #{$on-surface-light-disabled};

    position: relative;

    &.cds--tabs__nav-link {
      overflow: visible;
    }

    .cds--tabs__nav-item-label {
      line-height: 1.5rem;
    }
  }

  &--gray &__tab {
    --cds-layer: #{$surface-02};
  }

  &--white &__tab {
    --cds-layer: #{$surface-01};
  }

  &.cds--tabs.cds--tabs--contained &__tab .cds--tabs__nav-item-label {
    line-height: 3rem;
  }

  &.cds--tabs.cds--tabs--contained .cds--tabs__nav-item {
    &--selected {
      box-shadow: inset 0 4px 0 -1px $belimo-orange-carbon-hovered;
    }
  }

  // styles for excluded tabs
  &.cds--tabs:not(.cds--tabs--contained) .cds--tabs__nav-item {
    padding: 0.75rem 1.5rem;
    margin-inline-start: 0;
    background-color: transparent;
    margin: 0;
    border-block-end: 2px solid transparent;

    &:hover {
      border-block-end: 2px solid $belimo-orange-carbon-hovered;
    }

    &--selected {
      border-block-end: 2px solid $belimo-orange-carbon-hovered;
    }

    &--disabled {
      opacity: 0.6;

      &:hover {
        border-block-end: none;
      }
    }
  }

  &.cds--tabs .cds--tabs__nav-item {
    &:focus {
      outline: none;
    }
  }

  &--full-width.cds--tabs {
    width: 100%;
  }
}
