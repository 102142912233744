@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;

.bp-project-toolbar {
  display: flex;
  background-color: $surface-01;
  border-bottom: 2px solid $border-02;
  padding: 1.5rem 2rem 0;
  min-width: 0;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }

  &__project {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin: 1rem 0;
  }

  &__header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__share-button {
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    font-size: 1rem;
    color: $belimo-orange-carbon;

    &:is(:hover, :focus, :active) {
      color: $belimo-orange-carbon;
    }
  }

  & .cds--btn--ghost svg {
    fill: $belimo-orange-carbon;
  }

  &__project-title {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 2.5rem;
    line-height: 3rem;
    color: $on-surface-light-primary;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    width: fit-content;
  }

  &__project-title-name {
    display: inline-block;
    width: fit-content;
    max-width: 660px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__project-chevron {
    width: 2rem;
    height: 2rem;
  }

  &__project-details {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__project-image {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    height: 4rem;
    width: 5rem;
  }

  &__project-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__project-address {
    max-width: 660px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__project-goals {
    max-width: 660px;
    overflow: hidden;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &__project-description {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
  }

  &__controls {
    display: flex;
    width: 100%;
  }

  .cds--tabs:not(.cds--tabs--contained) .cds--tabs__nav-item:not(
  .cds--tabs__nav-item--selected):not(.cds--tabs__nav-item--disabled):hover {
    border-block-end: 2px solid $belimo-orange-carbon-hovered;
  }

  &__tab {
    &.cds--tabs__nav-item {
      border-block-end: none;

      &--selected {
        border-block-end: 2px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        outline: 2px solid $belimo-orange-carbon-hovered;
      }

      &:hover {
        border-block-end: 2px solid $belimo-orange-carbon-hovered;
      }
    }
  }

  &__beta {
    position: absolute;
    top: -1rem;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 0.625rem;
    line-height: 0.8rem;
    background-color: $surface-03;
    padding: 0.125rem 0.375rem;
    border-radius: 0.125rem;
  }
}
