@use "styles/variables" as *;

.bp-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: $surface-01;

  &__links {
    display: flex;
    gap: 1rem;
  }

  &__link {
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: underline;
    color: $belimo-orange-carbon-hovered;
    text-underline-offset: 0.4rem;
    text-decoration-thickness: 1px;
  }

  &__address {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-primary;
  }
}
