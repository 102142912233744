@use "styles/variables" as *;

.bp-custom-modal {
  padding: 1rem;

  // danger modal overrides
  &.cds--modal--danger {
    .cds--modal-container {
      background-color: $surface-danger-01;
    }
    .cds--modal-header {
      padding: 1.5rem;
      margin: 0;

      &__label {
        font-size: 0.75rem;
        line-height: 1rem;
        color: $on-surface-light-primary;
        text-transform: uppercase;
        font-weight: 300;
        margin-block-end: 0.5rem;
      }

      &__heading {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: $font-weight-regular;
        color: $on-surface-light-danger;
      }
    }
    .cds--modal-close-button {
      inset-block-start: 1rem;
      inset-inline-end: 1rem;
    }
    .bp-lead-text {
      color: $on-surface-light-danger;
      padding-right: 3rem;
    }
    .cds--modal-content {
      padding: 0 1.5rem 2.5rem;
    }
    .cds--modal-footer {
      .cds--btn {
        line-height: 2rem;
      }
      .cds--btn--primary {
        color: $on-interaction-danger-light;
        background-color: $interaction-danger;

        &:hover {
          background-color: $interaction-danger-hover;
        }
      }
    }
  }

  // white background for the whole modal
  .cds--modal-container {
    background-color: $surface-01;
    inline-size: 100%;

    &--sm {
      max-inline-size: 640px;
    }

    &--md {
      max-inline-size: 960px;
    }

    &--lg {
      max-inline-size: 1280px;

      .cds--modal-footer {
        padding-left: 50%;
      }
    }
  }

  .cds--modal-header {
    padding: 1.5rem;
    margin: 0;

    &__label {
      font-size: 1.5rem;
      line-height: 2rem;
      color: $on-surface-light-primary;
    }

    &__heading {
      font-size: 1.125rem;
      line-height: 2rem;
      font-weight: 300;
      color: $on-surface-light-secondary;
    }
  }

  .cds--modal-close-button {
    inset-block-start: 2rem;
    inset-inline-end: 1.5rem;

    button {
      display: flex;
      align-items: center;
      padding: 0;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  // modal body
  .cds--modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    mask-image: none;
  }

  .cds--modal-footer {
    block-size: 3rem;
    border-top: 1px solid $border-03;
    background-color: $surface-01;

    .cds--btn {
      align-items: center;
      box-shadow: none;
      block-size: auto;

      &--secondary {
        justify-content: flex-start;
      }
    }
  }

  &__button--loading {
    justify-content: center;
  }

  &--narrow-footer {
    .cds--modal-footer {
      justify-content: flex-end;

      .cds--btn {
        flex: 0 1 auto;
        width: fit-content;
        min-width: 250px;
      }
    }
  }

  // full screen modal
  &--full-screen {
    .cds--modal-container {
      width: 100%;
      margin: 2rem;
    }

    .cds--modal-footer {
      justify-content: flex-end;

      .cds--btn {
        flex: 0 1 auto;
        width: fit-content;
        min-width: 250px;
      }
    }
  }

  &--shaded {
    .cds--modal-header {
      border-bottom: 1px solid $border-03;
    }

    .cds--modal-content {
      background-color: $surface-02;
    }
  }
}
