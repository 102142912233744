@use "styles/variables" as *;

.bp-user-list-actions {
  display: flex;
  width: 100%;

  &__search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__search {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 3rem;
    background-color: $surface-01;
    border: none;
    line-height: 1.5rem;
    width: 100%;
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
    font-size: 1.5rem;
  }

  &__clear-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &__icon {
    font-size: 1.5rem;
  }
}
