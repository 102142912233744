@use "@carbon/motion" as *;
@use "styles/variables" as *;

.bp-profile-header {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  padding-inline: 2rem;

  background-color: $surface-01;

  &__content {
    display: grid;
    grid:
      "breadcrumbs actions" minmax(1.5rem, auto)
      "title       actions" minmax(3rem, auto) / 1fr auto;
    row-gap: 1rem;
    inline-size: 100%;
    max-inline-size: 1280px;
    margin-inline: auto;
    padding-block: 2rem 2.5rem;
  }

  &__breadcrumbs {
    grid-area: breadcrumbs;
    place-self: start;
  }

  &__title {
    grid-area: title;
    place-self: end start;

    font-size: 2.5rem;
    font-weight: $font-weight-regular;
    line-height: 3rem;

    color: $on-surface-light-primary;
  }

  &__actions.cds--btn {
    min-inline-size: 15.625rem;
    align-self: end;

    font-size: 1.125rem;
    font-weight: $font-weight-regular;
    line-height: 2rem;
  }

  &__extension {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1280px;
    margin: auto;
  }
}
