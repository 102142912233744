.bp-add-user-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__row {
    display: flex;
    gap: 1rem;
  }

  &__input {
    width: 100%;
  }
}
