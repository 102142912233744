@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-output-document {
  width: 100%;
  height: 100%;
  padding: 5mm;
  overflow-x: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &__page {
    display: block;
    background: #fff;
    box-shadow: 0 6pt 9pt 3pt $background-paper-elevation-level-05;
    user-select: none;

    & + & {
      margin-top: 5mm;
    }
  }
}
