.bp-hvac-page {
  padding: 1.5rem 2rem 2rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1280px;
    margin: 0 auto;
  }
}
