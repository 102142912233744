@use "styles/variables" as *;

.bp-report-document-page-amount {
  flex: 1 1 50%;

  &__title {
    font-size: 7.5pt;
    line-height: 12pt;
    font-weight: $font-weight-light;
    color: $on-surface-light-secondary;
    height: 12pt;
  }

  &__amount {
    font-size: 22pt;
    line-height: 27pt;
    border-bottom: 0.75pt solid $border-04;
    height: 33pt;
  }
}
