@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-report-form-document {
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
}
