@use "styles/variables" as *;

.bp-tooltip {
  position: relative;
  display: flex;

  &__target {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;

    &:hover {
      & + .bp-tooltip__content {
        animation: 300ms fade-in;
        animation-fill-mode: forwards;
      }

      & + .bp-tooltip__content--disabled {
        animation: none;
      }
    }

    &--disabled {
      cursor: auto;
    }
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    gap: 0.25rem;
    top: calc(0.5rem + 100%);
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    background-color: $on-surface-light-secondary;
    color: $surface-03;
    padding: 0.125rem 1rem;
    max-width: 240px;
    z-index: 7;
    border-radius: 0.125rem;

    &::after {
      content: " ";
      position: absolute;
      background-color: $on-surface-light-secondary;
      width: 0.4rem;
      height: 0.4rem;
      top: -0.2rem;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-weight-regular;
    color: $on-interaction-light;
    margin: 0;
  }

  &__value {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: $font-weight-regular;
    color: $surface-03;
    margin: 0;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
}
