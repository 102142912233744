@use "styles/variables" as *;

.bp-evaluation-results-charts-graph {
  &__graph-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 50%;
  }

  &__graph {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__legend-wrapper {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 13.5pt;
    width: 100%;
  }

  &__legend-row {
    display: flex;
    align-items: center;
    gap: 2pt;
  }

  &__legend-icon {
    width: 9pt;
    height: 9pt;
  }

  &__legend-name {
    font-size: 7.5pt;
    line-height: 13.5pt;
    color: $on-surface-light-tertiary;
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    background-color: $surface-black;
    border-radius: 1.5pt;
    overflow: hidden;
    padding-right: 5pt;
  }

  &__tooltip-row {
    min-height: 24pt;
    color: $on-interaction-light;
    display: flex;
    align-items: center;
    gap: 10pt;
  }

  &__tooltip-color {
    flex: 0 0 auto;
    width: 5pt;
    align-self: stretch;
  }

  &__tooltip-name {
    font-size: 10pt;
  }

  &__tooltip-value {
    margin-left: auto;
    font-size: 10pt;
  }

  &__empty {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-40%) translateY(-40%);
    padding: 9pt;
    border: 3pt solid $border-danger;
    color: $interaction-danger;
    font-size: 10pt;
    line-height: 15pt;
  }

  &__y-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  &__y-label-text {
    position: relative;
    left: -50%;
    transform: rotate(-90deg);
    opacity: 0.6;
  }

  &__x-label {
    position: absolute;
    left: 50%;
    bottom: -10pt;
  }

  &__x-label-text {
    opacity: 0.6;
  }
}
