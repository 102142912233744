@use "styles/variables" as *;

.bp-decimal-field {
  input {
    font-size: 1rem;
    font-weight: $font-weight-light;
    line-height: 1.125rem;
    border-bottom: 1px solid $border-04;

    &:hover {
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
    }

    &:focus {
      background-color: $surface-03;
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
      outline: none;
    }
  }

  &--sm {
    input {
      block-size: 2rem;
      padding: 0 1rem;
    }
  }

  &--md {
    input {
      block-size: 2.5rem;
      padding: 0.45rem 1rem 0.55rem;
    }
  }

  &--lg {
    input {
      block-size: 3rem;
      padding: 0.75rem 1rem;
    }
  }

  &--gray {
    input {
      --cds-field-02: #{$surface-02};
      background-color: $surface-02;
    }
  }

  &--white {
    input {
      background-color: $surface-01;
    }
  }

  &--end-adorned {
    input {
      padding-right: 3.5rem;
    }
  }

  &--outlined {
    input {
      border-left: 1px solid $border-04;
      border-bottom: 1px solid $border-04;

      &:hover {
        border-left: 1px solid $belimo-orange-carbon-hovered;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        border-left: none;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
        outline: none;
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: fit-content;
  }

  &__end-adornment {
    position: absolute;
    top: 0.35rem;
    right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $on-surface-light-secondary;
    font-weight: $font-weight-light;
  }

  &--sm + &__end-adornment-with-label {
    top: 2.125rem;
  }

  &--md + &__end-adornment-with-label {
    top: 2.3rem;
  }

  &--lg + &__end-adornment-with-label {
    top: 2.5rem;
  }

  &--disabled {
    input {
      &:hover {
        border-bottom: none;
      }
    }
  }

  &--invalid {
    label.cds--label {
      color: $on-surface-light-danger;
    }

    input {
      border-bottom: 1px solid $border-danger;
    }
  }
}
