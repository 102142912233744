@use "styles/variables" as *;

.bp-saving-tile-card {
  flex: 0 1 calc(50% - 0.5em);
  background-color: $surface-02;
  min-width: 0;
  padding: 0.875em;
  border: 0.05em solid $border-03;

  &__title {
    font-size: 0.875em;
    line-height: 2.3em;
    border-bottom: 0.05em solid $border-04;
    margin-bottom: 1.15em;
    min-height: 2.4em;
  }

  &--sm &__title {
    margin-bottom: 1em;
  }

  &__details {
    margin-bottom: 1em;
  }

  &--sm &__details {
    margin-bottom: 0.5em;
  }

  &__icon {
    font-size: 1.175em;
    color: $on-surface-light-primary;
    margin-bottom: 0.5em;
  }

  &--sm &__icon {
    margin-bottom: 0;
  }
}
