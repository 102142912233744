@use "@carbon/react/scss/breakpoint" as *;
@use "styles/variables" as *;
@use "styles/mixins" as *;

.bp-hvac-result-energy-view {
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
  padding: 2rem;
  background-color: $surface-01;

  &__row {
    display: flex;
    gap: 1.5rem;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    &--disabled {
      opacity: 0.6;
    }
  }

  &__title-wrapper {
    display: flex;
    gap: 12px;
  }

  &__title-icon {
    font-size: 2rem;
  }

  &__divider {
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid $border-03;
    border-bottom: none;
    width: 100%;
    margin: 0;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    @include breakpoint-up("lg") {
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.5rem;
    width: 32%;

    &--disabled {
      opacity: 0.6;
    }
  }

  &__tooltip {
    height: 18px;

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
