@use "styles/variables" as *;

.bp-text-field {
  --cds-support-error: transparent;

  input {
    font-size: 1rem;
    font-weight: $font-weight-light;
    line-height: 1.125rem;
    border-bottom: 1px solid $border-04;

    &:hover {
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
    }

    &:focus {
      background-color: $surface-03;
      border-bottom: 1px solid $belimo-orange-carbon-hovered;
      outline: none;
    }
  }

  &--sm {
    input {
      block-size: 2rem;
      padding: 0.25rem 0.75rem;
    }
  }

  &--md {
    input {
      block-size: 2.5rem;
      padding: 0.5rem 1rem;
    }
  }

  &--lg {
    input {
      block-size: 3rem;
      padding: 0.75rem 1rem;
    }
  }

  &--xlg {
    input {
      block-size: 3.5rem;
      padding: 0.75rem 1rem;
    }
  }

  &--gray {
    .cds--text-input__field-wrapper {
      .cds--text-input {
        background-color: $surface-02;
      }
    }
  }

  &--white {
    .cds--text-input__field-wrapper {
      .cds--text-input {
        background-color: $surface-01;
      }
    }
  }

  &--outlined {
    input {
      border-left: 1px solid $border-04;
      border-bottom: 1px solid $border-04;

      &:hover {
        border-left: 1px solid $belimo-orange-carbon-hovered;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
      }

      &:focus {
        border-left: none;
        border-bottom: 1px solid $belimo-orange-carbon-hovered;
        outline: none;
      }
    }
  }

  &--invalid {
    --cds-text-error: #{$on-surface-light-danger};

    input {
      border-bottom: 1px solid $border-danger;
    }

    label.cds--label {
      color: $on-surface-light-danger;
    }
  }

  &--disabled {
    input {
      border: none;
      cursor: not-allowed;

      &:hover {
        border: none;
      }

      &:focus {
        border: none;
      }
    }
  }
}
